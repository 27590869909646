import React, { Component, useCallback } from "react";
import logo from "../img/logo.svg";
import couponCode from "../img/couponCode.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
	getRipartiamoFAQ,
	getRipartiamoCounters,
	discountCodeGetInfo,
} from "../Services/Api";
import LoadSpinner from "../Components/LoadSpinner";
import ClusuNumbers from "../Components/ClusuNumbers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { ErrorComponent } from "../Components/CustomInputFieldError";
import { discountCodeRegex } from "../Services/Globals";
import cn from "classnames";
import { parseISO as parseISODate, parse as parseDate, format as formatDate } from "date-fns";
import { it as dateITLocale } from "date-fns/locale";

const Faq = ({ items }) => {
	const [state, setState] = useState({
		items: [],
	});

	useEffect(() => {
		setState({
			items: items.map((item) => {
				return { ...item, collapsed: true };
			}),
		});
	}, [items]);

	const toggleFaq = useCallback((index) => {
		setState((prevState) => {
			return {
				...prevState,
				items: [
					...prevState.items.slice(0, index),
					{
						...prevState.items[index],
						collapsed: !prevState.items[index].collapsed,
					},
					...prevState.items.slice(index + 1),
				],
			};
		});
	}, []);

	const elements = state.items.map((item, i) => (
		<li key={i}>
			<dl>
				<dt onClick={() => toggleFaq(i)} style={{ cursor: "pointer" }}>
					{item.title}
				</dt>
				<dd className={cn("collapsable", { visible: !item.collapsed })}>
					{item.description}
				</dd>
			</dl>
		</li>
	));

	return <ol>{elements}</ol>;
};

class Numbers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countersLoading: true,
			countersAlert: { visible: false, msg: "", status: "OK" },
			counters: {},
		};
	}

	componentDidMount() {
		const getData = async () => {
			try {
				const counters = await getRipartiamoCounters();
				this.setState({
					countersLoading: false,
					counters: counters,
				});
			} catch (e) {
				this.setState({
					countersAlert: { ...e, visible: true },
				});
			}
		};

		getData();
	}

	closeCountersAlert = () => {
		this.setState((prevState) => {
			return {
				countersAlert: { ...prevState.countersAlert, visible: false },
			};
		});
	};

	render() {
		return (
			<section className="yellow-section">
				<div className="container info-section">
					<LoadSpinner
						loadingMessage="Caricamento"
						isLoading={this.state.countersLoading}
						alert={this.countersAlert}
						onCloseAlert={this.closeCountersAlert}>
						<div className="row">
							<ClusuNumbers value={this.state.counters.count}>
								Codici
								<br />
								promozionali
							</ClusuNumbers>

							<ClusuNumbers
								value={this.state.counters.totalCoupon}>
								Clusù
								<br />
								Generati
							</ClusuNumbers>

							<ClusuNumbers
								value={this.state.counters.totalMoney}
								uom="€">
								Trasferiti
								<br />
								alle attività
							</ClusuNumbers>
						</div>
					</LoadSpinner>
				</div>
			</section>
		);
	}
}

class DiscountCodeForm extends Component {
	initalValues = {
		discountCode: "",
	};

	validationSchema = yup.object().shape({
		discountCode: yup
			.string()
			.matches(discountCodeRegex, "Codice non valido")
			.required("Campo richiesto"),
	});

	render() {
		return (
			<Formik
				initialValues={this.initalValues}
				validationSchema={this.validationSchema}
				onSubmit={this.props.onSubmit}>
				<Form>
					<div className="form-group">
						<label>
							Codice promozionale{" "}
							<span className="required-field">*</span>
						</label>
						<div className="input-group">
							<Field name="discountCode">
								{({ field, form, meta }) => (
									<input
										placeholder="Codice promozionale"
										{...field}
										className={cn(
											"form-control",
											meta.touched && meta.error
												? "is-invalid"
												: ""
										)}
									/>
								)}
							</Field>

							<div className="input-group-append">
								<button
									type="submit"
									className="btn btn-outline-primary">
									Verifica codice
								</button>
							</div>

							<ErrorMessage
								name="discountCode"
								component={ErrorComponent}
							/>
						</div>
					</div>
				</Form>
			</Formik>
		);
	}
}

class CheckDiscount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			alert: { visible: false, status: "OK", msg: "", statusCode: "" },
			discountData: null,
		};
	}

	onSubmitCode = async (form) => {
		this.setState({
			discountData: null,
			isLoading: true,
			alert: { visible: false, status: "OK", msg: "", statusCode: "" },
		});

		//fetch data
		try {
			const data = await discountCodeGetInfo(form.discountCode);
			this.setState({
				isLoading: false,
				discountData: data,
			});
		} catch (e) {
			console.log(e);
			this.setState({
				isLoading: false,
				alert: { ...e, visible: true },
			});
		}
	};

	dateFormat="eee d MMM yyyy";
	dateTimeFormat = "eee d MMM yyyy HH:mm:ss";
	formatDateOpt = { locale: dateITLocale };

	computeDiscountCodeTable = () => {
		if (this.state.discountData) {
			const table = this.state.discountData.transactions.map(
				(purchase, i) => {
					const date = parseISODate(purchase.date);
					const downloadLink = process.env.REACT_APP_API_BASE_URL + 'api/coupon/'+purchase.couponCode+'/pdf'
					return (
						<tr key={i}>
							<td>
								{formatDate(
									date,
									this.dateTimeFormat,
									this.formatDateOpt
								)}
							</td>
							<td>{purchase.shopName}</td>
							<td>{purchase.promoCodeAmount}&nbsp;€</td>
							<td>{purchase.couponCode}</td>
							<td><a href={downloadLink}><i class="icofont-download"></i> Scarica</a></td>
						</tr>
					);
				}
			);


			const startDate= parseDate(this.state.discountData.startDate,"M/d/yyyy",new Date())
			const expireDate= parseDate(this.state.discountData.expirationDate,"M/d/yyyy",new Date())

			return (
				<>
					<div className="my-3">
						<span>
							<b>Valore residuo: </b>{" "}
							{this.state.discountData.remainedValue}€
							{" "}
						</span>
						<span>
							<b>Valido dal: </b>{" "}
							{formatDate(startDate,this.dateFormat,this.formatDateOpt)}
							{" "}
						</span>
						<span>
							<b>Scadenza: </b>{" "}
							{formatDate(expireDate,this.dateFormat,this.formatDateOpt)}
						</span>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th scope="col">Data</th>
								<th scope="col">Negozio</th>
								<th scope="col">Importo utilizzato</th>
								<th scope="col">Codice Clusù</th>
								<th scope="col">Scarica Coupon</th>
							</tr>
						</thead>
						<tbody>{table}</tbody>
					</table>
				</>
			);
		} else return null;
	};

	render() {
		return (
			<section>
				<div className="container">
					<div className="row justify-content-center mb-5">
						<div className="col-xl-10">
							<div className="card clusu-card">
								<div className="card-header">
									Verifica il tuo codice promozionale
								</div>
								<div className="card-body">
									<DiscountCodeForm
										onSubmit={this.onSubmitCode}
									/>

									<LoadSpinner
										isLoading={this.state.isLoading}
										loadingMessage="Caricamento..."
										alert={this.state.alert}>
										{this.computeDiscountCodeTable()}
									</LoadSpinner>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default function Ripartiamo() {
	const scrollToContent = () => {
		document
			.getElementById("content")
			.scrollIntoView({ behavior: "smooth" });
	};

	const videoId = process.env.REACT_APP_RIPARTIAMO_VIDEO_ID;
	const videoURL = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

	const [state, setState] = useState({
		faqList: [],
		faqLoading: true,
	});
	useEffect(() => {
		async function fetchFaq() {
			const list = await getRipartiamoFAQ();
			setState({
				faqList: list,
				faqLoading: false,
			});
		}
		fetchFaq();
	}, []);

	return (
		<>
			<header id="intro">
				<div className="container">
					<div className="bg-gradient"></div>

					<div id="logo">
						<Link to="/">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div id="intro-content">
						<p
							style={{
								fontFamily: "beauty_and_the_beastregular",
							}}>
							3,2,1...Ripartiamo!
						</p>
						<p>
							L’iniziativa dell'Amministrazione
							<br /> Comunale per ringraziare
							<br /> i Cittadini da 0 a 18 anni
							<br /> e sostenere le attività commerciali
						</p>

						<div>
							<button onClick={scrollToContent}>
								SCOPRI LA PROMOZIONE!
							</button>
						</div>
					</div>
				</div>

				<div id="mouse-scroll">
					<span>
						<i className="icofont-scroll-down"></i>
					</span>
				</div>
			</header>

			<div className="container" id="content">
				<section className="info-section">
					<div className="row justify-content-center">
						<section className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									COS&rsquo;&nbsp;&Egrave;
								</span>
							</div>

							<p className="info-content text-center">
								321.. CON TE RIPARTIAMO è un’iniziativa
								dell'Amministrazione Comunale, che, vista
								l'emergenza Covid-19, ha pensato di sostenere e
								ringraziare i Cittadini da 0 a 18 anni regalando
								un buono del valore di 50€ da spendere presso le
								attività del Comune di Clusone dal{" "}
								<b>15 Luglio</b>.. attraverso la piattaforma
								CluSU!
							</p>
						</section>
					</div>
					<div className="row">
						<section className="col-12">
							<div
								className="info-header"
								style={{ marginBottom: "3.5vh" }}>
								<span className="bottom-line">
									COME FUNZIONA
								</span>
							</div>

							<div className="row">
								<div className="col-md-6 coupon-example">
									<img src={couponCode} alt="promocode" />
								</div>

								<div className="col-md-6">
									<p className="info-content">
										Una volta ritirato il{" "}
										<b>tuo voucher nominativo</b> presso il
										Comune di Clusone, naviga sul sito{" "}
										<b>www.clusu.com</b>: qui troverai tutte
										le attività che hanno aderito, fino ad
										ora, alla piattaforma.
									</p>

									<p className="info-content">
										<b>Seleziona l'attività</b> dove vuoi
										acquistare,
										<br />
										<b>Scegli l'importo</b> che desideri ed{" "}
										<b>
											inserisci il tuo codice promozionale
										</b>{" "}
										contenuto nel voucher;
										<br />
										confermato l'acquisto, attendi qualche
										istante e controlla la tua casella
										email: troverai il tuo <b>Clusù</b>!
									</p>

									<p className="info-content">
										Se l’importo che hai selezionato è
										maggiore del valore del buono,{" "}
										<b>
											puoi pagare in modo sicuro con
											PayPal o prepagata!
										</b>
										<br />
										Il tuo codice ha un valore di 50€ e può
										essere utilizzato in negozi diversi.
									</p>

									<p className="info-content">
										Hai dei dubbi? Leggi più dettagli qui
										sotto, guarda il video e se hai bisogno
										di aiuto, contattaci a{" "}
										<a href="mailto:info@clusu.com">
											info@clusu.com
										</a>
										.
									</p>
								</div>
							</div>

							<div className="row">
								<div className="col-12 embedded-video">
									<div className="video-container">
										<iframe
											id="ytplayer"
											type="text/html"
											width="640"
											height="360"
											title="Promo video"
											src={videoURL}
											frameBorder="0"
										/>
									</div>
								</div>
							</div>
						</section>
					</div>

					<div className="row">
						<section className="col-12">
							<div className="info-header">
								<span className="bottom-line">FAQ</span>
							</div>
							<div className="info-content">
								<LoadSpinner
									isLoading={state.faqLoading}
									loadingMessage="Caricamento">
									<Faq items={state.faqList} />
								</LoadSpinner>
							</div>
						</section>
					</div>
				</section>
			</div>

			<CheckDiscount />

			<Numbers />
		</>
	);
}
