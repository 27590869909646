import React from "react";
import ShopsList from "../../Components/ShopsList";
import { Extras, Shop } from "../../models";

/*function toggleItemInArray<T>(array: Array<T>, item: T): Array<T> {
	const index = array.indexOf(item);
	if (index === -1) return [...array, item];
	else
		return [
			...array.slice(0, index),
			...array.slice(index + 1, array.length),
		];
}*/

const page = {
	pageSize: 0,
	currentPage: 0,
	totPages: 0,
};

const shops: Array<Shop> = [
	{
		id: "1",
		name: "Farmacia Centrale dott. Franco Personeni",
		address: "Piazza baradello 19 Clusone",
		description:
			"Da tre generazioni competenza e cortesia al servizio della salute",
		category: "Farmacia",
		image: "https://clusu.blob.core.windows.net/shops/29ccf8e9973f404a9e4d3c1f970fdae7/photos/original.jpeg",
		//delivery: true,
		extras: [Extras.CHARITY],
	},
	{
		id: "2",
		name: "Carminati Achille & C. SNC",
		address: "VIA BRESCIA, 4/A Clusone",
		description: "",
		category: "Alimentari",
		image: "",
		//delivery: false,
	},
	{
		id: "3",
		name: "Il Botteghino",
		address: "Via Fiorine n 24 Clusone",
		description: "",
		category: "Alimentari",
		image: "",
		//delivery: true,
		extras: [Extras.DELIVERY],
	},
	{
		id: "4",
		name: "FRANCO OLMO ... la cancelleria dal 1924",
		address: "Via San Vincenzo De Paoli ,9 Clusone",
		description: "CARTOLERIA, FORNITURE UFFICIO, SEVEN POINT, DOTE SCUOLA",
		category: "Cartoleria",
		image: "https://clusu.blob.core.windows.net/shops/1d525ea9750549a2b2a139d4144a9ade/photos/original.jpeg",
		//delivery: false,
		extras: [Extras.EXTRA_DISCOUNT_10],
	},
	{
		id: "5",
		name: "Panificio Balduzzi",
		address: "Piazza uccelli 9 Clusone",
		description:
			"Dal 1994 Angelo Balduzzi produce con passione artigianale e con i profumi di un tempo il pane, i prodotti da forno, i dolci e le torte, selezionando con cura la qualità delle materie prime e seguendo rigorosamente le tradizioni dell'arte bianca nei processi produttivi.",
		category: "Alimentari",
		image: "https://clusu.blob.core.windows.net/shops/29f29e23fa2e4ba5a49c6469ddbe7f83/photos/original.jpeg",
		//delivery: false,
		extras: [Extras.EXTRA_DISCOUNT_10],
	},
	{
		id: "6",
		name: "BRUMS - Abbigliamento 0 - 16 anni",
		address: "Piazza Martiri della Libertà 11 - Clusone",
		description:
			"Abbigliamento neonati, bambini e ragazzi da zero a 16 anni",
		category: "Bambini",
		image: "https://clusu.blob.core.windows.net/shops/d61c0e9a3adc45e7b358205457b4304e/photos/original.jpeg",
		//delivery: true,
		extras: [],
	},
	{
		id: "7",
		name: "Farmacia Dr. Antonio Pedenovi",
		address: "viale Gusmini, 16 - BG Clusone",
		description: "Farmacia specializzata sul naturale e Cosmesi",
		category: "Farmacia",
		image: "https://clusu.blob.core.windows.net/shops/default.jpg",
		//delivery: true,
		extras: [],
	},
	{
		id: "8",
		name: "Iperal Supermercati - pv Clusone",
		address: "Viale Vittorio Emanuele 19 Clusone",
		description: "",
		category: "Supermarket",
		image: "",
		//delivery: false,
		extras: [Extras.CHARITY],
	},
	{
		id: "9",
		name: "bergamo supermercati srl Conad superstore",
		address: "via gusmini, 8 Clusone",
		description: "",
		category: "Supermarket",
		image: "",
		//delivery: false,
	},
	{
		id: "10",
		name: "MINUSCOLI",
		address: "PIAZZA PARADISO 3 Clusone",
		description: "",
		category: "Alimentari",
		image: "",
		//delivery: false,
		extras: [Extras.EXTRA_DISCOUNT_10],
	},
	{
		id: "11",
		name: "Dress junior",
		address: "Via Guglielmo Marconi, 7 Clusone",
		description: "Negozio abbigliamento bambini 0-16 anni",
		category: "Bambini",
		image: "https://clusu.blob.core.windows.net/shops/666fa514ee98411693fe99af69668928/photos/original.jpeg",
		//delivery: false,
	}
];

export default function HOCVouchers() {
	/*const [cities, setCities] = useState<Array<string>>([]);
	const [categories, setCategories] = useState<Array<Category>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [selectedCities, setSelectedCities] = useState<Array<string>>([]);
	const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
		[]
	);
	const [shops, setShops] = useState<Array<Shop>>([]);
	const [page, setPage] = useState<PageInfo>({
		pageSize: 0,
		currentPage: 0,
		totPages: 0,
	});

	const clearFilters = useCallback(() => {
		setSelectedCities([]);
		setSelectedCategories([]);
	}, [setSelectedCities, setSelectedCategories]);

	const selectCity = useCallback(
		(city: string) => {
			setSelectedCities(toggleItemInArray(selectedCities, city));
		},
		[setSelectedCities, selectedCities]
	);

	const selectCategory = useCallback(
		(category: Category) => {
			setSelectedCategories(
				toggleItemInArray(selectedCategories, category.id)
			);
		},
		[setSelectedCategories, selectedCategories]
	);

	const loadNextPage = useCallback(() => {}, []);

	useEffect(() => {
		setCities([]);
		setCategories([]);
		setShops([
			{
				name: "prova1",
				id: "1",
				category: "abbigliamento",
				description: "Negozio 1",
			},
			{
				name: "prova2",
				id: "2",
				category: "alimentari",
				description: "un negozio di alimentari",
			},
		]);
	}, [setCities]);*/

	return (
		<ShopsList
			cities={[]}
			categories={[]}
			isLoading={false}
			error={undefined}
			selectedCities={[]}
			selectedCategories={[]}
			selectCity={() => {}}
			selectCategory={() => {}}
			clearFilters={() => {}}
			shops={shops}
			page={page}
			loadNextPage={() => {}}
		/>
	);
}
