import React, { PropsWithChildren } from "react";

interface Props {
	isLoading: boolean;
	error?: Error;
}

export default function LoadingProgress(props: PropsWithChildren<Props>) {
	const { isLoading, error, children } = props;

	if (isLoading)
		return (
			<div className="row justify-content-center my-5">
				<div className="splash-screen-container">
					<div className="text-center">
						<div className="spinner-border" role="status" />
						<h5>Caricamento</h5>
						<p className="text-muted">
							<small>Attendere prego</small>
						</p>
					</div>
				</div>
			</div>
		);
	else if (error)
		return (
			<div className="text-center alert alert-danger">
				<p>Si è verificato un errore</p>
				<p className="text-muted">
					<small>{error.message}</small>
				</p>
			</div>
		);
	else if (children) return <>{children}</>;
	else return null;
}
