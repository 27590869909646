import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const isOldBrowser = browser.satisfies({
	ie: "<=11",
	firefox: "<=27",
	opera: "<=12",
	safari: "<=6",
	chrome: "<=20",
});

if (isOldBrowser) {
	document.getElementById("old-browser-alert").classList.remove("hidden");
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

//load paypal script
const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const paypalScript = document.createElement("script");

paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=EUR`;
paypalScript.async = true;

document.body.appendChild(paypalScript);
