import React from "react";
import Shops from "./Shops";
import "./style.scss";
import { FiltersProps } from "./Filters";
import { ShopsProps } from "./Shops";

export type ShopListProps = Omit<FiltersProps, "visibleFilters"> & ShopsProps;

export default function Index(props: ShopListProps) {
	/*const [visibleFilters, setVisibleFilters] = useState<boolean>(false);

	const toggleFilters = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			setVisibleFilters((oldState) => !oldState);
		},
		[setVisibleFilters]
	);*/

	return (
		<section className="my-4">
			{/*<section className="row mb-3 filters-collapse-button">
				<div className="col-12">
					<button
						className="col-12 btn btn-outline-light"
						onClick={toggleFilters}>
						<i className="icofont-filter"></i>Filtri
					</button>
				</div>
	</section>*/}

			<section className="row">
				{/*<Filters visibleFilters={visibleFilters} {...props} />*/}

				<Shops {...props} />
			</section>
		</section>
	);
}
