export const INIT_STATE = 'init_state';
export const SELECTED_SHOP_STATE = 'selected_shop_state';
export const CHECKOUT_STATE = 'checkout_state';
export const PURCHASE_COMPLETE_STATE = 'purchase_complete_state';
export const PURCHASE_ERROR_STATE = 'purchase_error_state';
export const PREVIEW_STATE = 'preview_state';
export const CHECKOUT_FORM_SUBMITTING_STATE = 'checkout_form_submitting';

export const COUPON_LOADING = 'coupon_loading';
export const COUPON_CHECK_STATE = 'coupon_check_state';
export const COUPON_REEDEMED_STATE = 'coupon_redemed_state';
export const COUPON_ERROR_STATE = 'coupon_error_state';