
export const couponCodeRegex = /^[0-9A-Z]{4}-[0-9A-Z]{4}$/;
export const couponSecretCodeRegex = /^[0-9A-Z]{4}-[0-9A-Z]{4}$/;
export const shopCodeRegex=/^[0-9A-Z]{5}$/;

export const discountCodeRegex = /^[0-9A-Z]{3}-[0-9A-Z]{4}-[0-9A-Z]{4}$/

export const calcFinalPayment = (payment,discount)=>{
    let final = payment - discount
    if (final <0)
        return 0;
    else
        return final;
}