import React from "react";
import logo from "../img/logo.svg";
import { Link } from "react-router-dom";
import image from "../img/shopping_vouchers_img.svg";
import HOCVouchers from "../routes/ShoppingVouchers/HOCVouchers";

const scrollToContent = () => {
	document.getElementById("content").scrollIntoView({ behavior: "smooth" });
};

const scrollToShopsList = () => {
	document
		.getElementById("shops_list")
		.scrollIntoView({ behavior: "smooth" });
};

export default function ShoppingVouchers() {
	return (
		<>
			<header id="intro" className="light-blu-bg">
				<div className="container">
					<div id="logo">
						<Link to="/">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div
						id="intro-content"
						style={{ top: "0" }}
						className="shopping-vouchers-intro">
						<div className="row">
							<div
								className="col-md-6 intro-title"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<p style={{ fontSize: "3.5vmin" }}>
									Benvenuto sullo spazio dedicato alle misure
									urgenti connesse all’emergenza da covid-19
									di solidarietà alimentare e di sostegno alle
									famiglie e per il pagamento dei canoni di
									locazione e delle utenze
									{/*Benvenuto sullo
									<br /> spazio dedicato ai
									<br />
									<b>buoni spesa!</b>*/}
								</p>

								<div>
									<button onClick={scrollToContent}>
										SCOPRI COME FUNZIONA
									</button>
								</div>
								<div>
									<a
										href="/buoni-spesa/esercente"
										className="btn">
										SEI UN ESERCENTE?
									</a>
								</div>
							</div>
							<div
								className="col-md-6"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<img
									src={image}
									alt="shopping vouchers logo"
									style={{
										maxWidth: "80%",
										maxHeight: "70vh",
										margin: "auto",
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div id="mouse-scroll">
					<span>
						<i className="icofont-scroll-down"></i>
					</span>
				</div>
			</header>

			<section className="info-section" id="content">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<p className="info-content text-center">
								Con il{" "}
								<b>
									<a
										href="https://clusone-api.cloud.municipiumapp.it/s3/20001/allegati/bando.pdf"
										target="_blank"
										rel="noopener noreferrer">
										bando PER L’EROGAZIONE DELLE MISURE
									</a>
								</b>
								<br />
								il Comune di Clusone, intende sostenere i
								cittadini più esposti agli effetti economici
								derivanti dall’emergenza epidemiologica da
								COVID-19 individuando la platea dei beneficiari
								e il relativo contributo tra i nuclei famigliari
								e quelli in stato di bisogno, per soddisfare la
								necessità più urgenti ed essenziali, attraverso
								misure urgenti di solidarietà alimentare e di
								sostegno alle famiglie per il pagamento dei
								canoni di locazione e delle utenze.
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									AVVISO APERTURA BANDO
								</span>
							</div>
							<p className="info-content text-center">
								<b>
									AVVISO APERTURA BANDO “MISURE URGENTI
									CONNESSE ALL’EMERGENZA DA COVID-19 DI
									SOLIDARIETÀ ALIMENTARE E DI SOSTEGNO ALLE
									FAMIGLIE PER IL PAGAMENTO DEI CANONI DI
									LOCAZIONE E DELLE UTENZE DOMESTICHE”. In
									esecuzione dell’articolo 53, comma 1 del
									D.L. del 25/05/2021 n. 73, recante “Misure
									urgenti connesse all'emergenza da COVID-19,
									per le imprese, il lavoro, i giovani, la
									salute e i servizi territoriali”
								</b>
								<br />
								La domanda
								<b>di adesione</b> è per una sola misura ma sarà
								possibile indicare inoltre una seconda opzione
								che, qualora le risorse a disposizione non
								risultassero completamente esaurite in 1
								valutazione delle domande verrà presa in
								considerazione mediante lo scorrimento sulla
								medesima graduatoria,{" "}
								<b>
									da parte di nuclei familiari residenti a
									Clusone
								</b>
								.
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									COME ADERIRE?
								</span>
							</div>
							<p className="info-content text-center">
								- Compilando la richiesta online raggiungibile{" "}
								<b>
									<a href="/buoni-spesa/richiesta">QUI</a>
								</b>{" "}
								<br />
								<br />- Compilando la{" "}
								<a
									href="https://clusone-api.cloud.municipiumapp.it/s3/20001/allegati/domanda.pdf"
									target="_blank"
									rel="noopener noreferrer">
									domanda
								</a>{" "}
								<b>
									e inviandola all’indirizzo mail:
									buonispesa@comune.clusone.bg.it.
								</b>{" "}
								<br />
								<br />- Consegnando la{" "}
								<a
									href="https://clusone-api.cloud.municipiumapp.it/s3/20001/allegati/domanda.pdf"
									target="_blank"
									rel="noopener noreferrer">
									domanda cartacea
								</a>{" "}
								direttamente all’ufficio Servizi Sociali{" "}
								<b>
									nei giorni di lunedì e mercoledì dalle 9.00
									alle 12.00 dal 25/11/2021 al 17/12/2021.
								</b>
							</p>
						</div>
					</div>
				</div>
			</section>

			<section
				style={{ backgroundColor: "#006F92", color: "white" }}
				className="info-section">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									CHI PUÒ FARE DOMANDA?
								</span>
							</div>
							<p className="info-content text-center">
								I destinatari del buono spesa devono essere in
								possesso dei seguenti requisiti:
							</p>
							<ol>
								<li className="info-content">
									Avere la <u>residenza anagrafica</u> nel
									Comune di Clusone;
								</li>
								<li className="info-content">
									Possedere{" "}
									<u>
										un’attestazione ISEE ordinario o
										corrente
									</u>{" "}
									inferiore o uguale a <u>Euro 26.000,00</u>.
								</li>
							</ol>
						</div>
					</div>

					<div className="row justify-content-center">
						<a
							className="btn white-button col-6"
							href="/buoni-spesa/richiesta">
							COMPILA LA RICHIESTA ONLINE
						</a>
					</div>
				</div>
			</section>

			<section style={{ position: "relative" }}>
				<div
					style={{
						backgroundColor: "#006F92",
						position: "absolute",
						width: "100%",
						height: "30%",
					}}
				/>
				<div className="container">
					<div className="info-section row justify-content-center">
						<div
							className="col-md-10"
							style={{
								backgroundColor: "#00B2EA",
								color: "white",
								borderRadius: "15px",
							}}>
							<div className="info-header">
								<span className="bottom-line">ATTENZIONE!</span>
							</div>
							<div className="row justify-content-center">
								<p className="info-content text-center col-10">
									<b>Sono condizione di esclusione:</b> essere
									assegnatari al momento della presentazione
									della domanda, con riferimento all’intero
									nucleo famigliare, di sostegni pubblici
									quali il Reddito di Cittadinanza, NASPI,
									Indennità di Mobilità, Cassa Integrazione
									Guadagni per importi superiori o uguali a:{" "}
									<br />
									<br />
									- € 800,00 mensili per nuclei composti da
									n.1/2 componenti; <br /> - € 1.000,00
									mensili per nuclei composti da n.3
									componenti; <br />- € 1.200,00 mensili per
									nuclei composti da n.4 o più componenti.
								</p>
							</div>
							<div className="row justify-content-center">
								<p className="info-content text-center col-10">
									NON POSSONO RICHIEDE LA{" "}
									<u>
										MISURA DI CONTRIBUTO UNA TANTUM PER IL
										PAGAMENTO DELL’AFFITTO
									</u>{" "}
									coloro che: <br />
									- sono in affitto presso Servizi abitativi
									pubblici;
									<br />- hanno già percepito nell’anno 2021
									contributi per sostenere il canone di
									affitto
								</p>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<p className="info-content">
								La richiesta può essere presentata, ed è
								ammessa, per un solo componente di uno stesso
								nucleo familiare anagrafico e per una sola
								misura con la possibilità di indicare una
								seconda opzione che, qualora le risorse a
								disposizione non risultassero completamente
								esaurite in 1 valutazione delle domande verrà
								presa in considerazione mediante lo scorrimento
								sulla medesima graduatoria.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="info-section">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span>
									IN COSA CONSISTE IL CONTRIBUTO ALIMENTARE E
									PER GENERI DI PRIMA NECESSITA’?
								</span>
								<div
									style={{
										position: "absolute",
										display: "block",
										margin: "auto",
										left: 0,
										right: 0,
										width: "80%",
										height: "3px",
										background: "#ffcc14",
									}}
								/>
							</div>
							<p className="info-content text-center">
								Si tratta di buoni spesa, fruibili solo per una
								volta da ciascun nucleo familiare richiedente
								nell’ambito del presente bando, è diversificato
								in relazione all’ampiezza del nucleo familiare e
								fino ad un importo massimo di Euro 475,00
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									DOVE POSSO SPENDERE I BUONI?
								</span>
							</div>
							<p className="info-content text-center">
								Il buono può essere utilizzato per acquisto di
								beni di prima necessità, quali: <br />
								<br />
								<b>
									<span style={{ marginLeft: "1rem" }}>
										- Generi alimentari e bevande, ad
										esclusione di alcolici;
									</span>
									<br />
									<span style={{ marginLeft: "1rem" }}>
										- Prodotti per l’igiene personale e per
										la casa;
									</span>
									<br />
									<span style={{ marginLeft: "1rem" }}>
										- c) Materiale didattico per attività
										scolastiche ed educative sino ad un
										massimo di € 75/studente componente il
										nucleo familiare;
									</span>
									<br />
									<span style={{ marginLeft: "1rem" }}>
										- Farmacia;
									</span>
								</b>
								<br />
								<br />
								Potrà essere speso presso gli esercizi
								commerciali fissi e ambulanti che esercitano la
								loro attività sul territorio di Clusone e che
								aderiranno all’iniziativa realizzata attraverso
								la collaborazione con l’Associazione “CluSU”
								sulla piattaforma omonima e a cui dovranno
								iscriversi.
							</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<button
							className="btn btn-primary col-6"
							onClick={scrollToShopsList}>
							Scopri le attività
						</button>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									ENTRO QUANDO DEVO SPENDERLI?
								</span>
							</div>
							<p className="info-content text-center">
								La validità del buono spesa verrà indicata sullo
								stesso e i buoni non spesi entro la data di
								scadenza indicata non saranno più spendibili.
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									IN COSA CONSISTE IL CONTRIBUTO PER LE
									UTENZE?
								</span>
							</div>
							<p className="info-content text-center">
								Si tratta di un contributo economico una tantum
								e sarà concesso a fronte del pagamento utenze
								attive per il consumo di Energia Elettrica, Gas,
								Acqua, ecc. diversificato in relazione
								all’ampiezza del nucleo familiare e fino ad un
								importo massimo di Euro 475,00, si prende come
								riferimento il pagamento avvenuto nell’anno
								2021, dal quale risulti l’utenza e relativo
								indirizzo, importo bolletta e periodo al quale
								essa si riferisce.
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									IN COSA CONSISTE IL CONTRIBUTO PER
									L’AFFITTO?
								</span>
							</div>
							<p className="info-content text-center">
								si tratta di un contributo economico una tantum
								equivalente al 20% del costo effettivamente
								sostenuto riferito all’anno 2021 con importo
								massimo di Euro 750,00.
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">INFO</span>
							</div>
							<p className="info-content text-center">
								Il bando completo, con tutte le informazioni in
								merito all’iniziativa, è consultabile sul sito
								istituzionale ed al seguente{" "}
								<a
									href="https://www.comune.clusone.bg.it/it/news/bando-per-lerogazione-di-misure-urgenti"
									target="_blank"
									rel="noopener noreferrer">
									LINK
								</a>
								.<br /> La presente informativa non è esaustiva
								al fine della comprensione del bando. Per
								ulteriori informazioni è possibile contattare
								l’ufficio Servizi Sociali al numero 034689616.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section
				className="info-section"
				id="shops_list"
				style={{ backgroundColor: "#006F92", color: "white" }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12">
							<div className="info-header">
								<span className="bottom-line">
									DOVE POSSO COMPRARE?
								</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<HOCVouchers />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
