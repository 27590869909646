import Axios from "axios";
import urljoin from "url-join";

import {
	trackCouponCheck,
	trackCouponRedeem,
	trackCouponRedeemError,
} from "../AppInsight";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const GET_SHOPS_URL = urljoin(API_BASE_URL, "/api/shops");
const CREATE_ORDER_URL = urljoin(API_BASE_URL, "/api/order");
const CHECK_COUPON_URL = urljoin(API_BASE_URL, "/api/coupon/");
const REDEEM_COUPON_URL = urljoin(API_BASE_URL, "/api/coupon/redeem");
const COMMENTS_LIST = urljoin(API_BASE_URL, "/api/wallmessages");
const COMMENT_POST = urljoin(API_BASE_URL, "/api/wallmessages");
const GET_MENU = urljoin(API_BASE_URL, "/api/menu/");
const UPLOAD_MENU = urljoin(API_BASE_URL, "/api/menu/upload");
const DISCOUNT_CHECK_URL = urljoin(API_BASE_URL, "/api/promocode/check");
const PURCHASE_COMPLETE_URL = urljoin(API_BASE_URL, "/api/order/completed");
const RIPARTIAMO_FAQ_URL = urljoin(API_BASE_URL, "/api/docs/321ripartiamo/faq");
const RIPARTIAMO_COUNTERS_URL = urljoin(
	API_BASE_URL,
	"api/promocode/counters/321"
);
const DISCOUNT_INFO_CODE_URL = urljoin(API_BASE_URL, "/api/promocode/");

const errorHandler = (error) => {
	if (error.response && error.response.data)
		return Promise.reject({
			...error.response.data,
			statusCode: error.status,
		});
	else
		return Promise.reject({
			status: "ERROR",
			msg: error.message,
			statusCode: error.status,
		});
};

const enrichShops = (data) => {
	return data.shops.map((shop) => {
		return { ...shop, preview: data.preview };
	});
};

export const getShopList = async () => {
	return Axios.get(GET_SHOPS_URL)
		.then((response) => {
			if (response.status === 204 || response.data === "")
				return { shops: [], preview: false };
			return response.data;
		})
		.then((data) => {
			return {
				list: enrichShops(data),
				categories: data.categories ? data.categories : [],
				contests: data.contest ? data.contest : [],
			};
		})
		.catch(errorHandler);

	/*return new Promise((resolve, reject) => {
		const list = [
			{
				name: "shop1",
				description: "negozio 1",
				id: 1,
				bonus: "20%",
				conditions: "Valido su tutti i prodotti",
				expiration: "06/05/2021",
				amounts: [
					{ payment: 5, value: 0.02 },
					{ payment: 10, value: 0.05 },
					{ payment: 15, value: 0.1 },
					{ payment: 20, value: 0.15 },
				],
				category: "Abbigliamento",
				image: "./panificio.jpg",
			},
			{
				name: "shop2",
				description: "negozio 2",
				id: 2,
				bonus: "20%",
				conditions: "Valido su tutti i prodotti",
				expiration: "06/05/2021",
				amounts: [
					{ payment: 1, value: 0.01 },
					{ payment: 2, value: 0.02 },
					{ payment: 3, value: 0.03 },
					{ payment: 4, value: 0.04 },
				],
				category: "Bambino",
				image: "./pizzeria.jpg",
			},
		];

		resolve({
			list: list,
			categories: [
				{ name: "Abbigliamento", color: "red" },
				{ name: "Bambino", color: "green" },
				{ name: "Alimentare", color: "green" }
			],
		});
	});*/
};

export const createOrder = async (order) => {
	//console.log("order", order);
	//send form data to server
	return Axios.post(CREATE_ORDER_URL, order)
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);

	/*return new Promise((resolve,reject)=>{
		reject({status:'PREVIEW',msg:'blabls'})
	});*/
};

export const checkCoupon = async ({ coupon_code }) => {
	trackCouponCheck({ coupon_code: coupon_code });
	const url = urljoin(CHECK_COUPON_URL, coupon_code);

	return Axios.get(url)
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);

	/*return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				shop_code: "fsdfds",
				coupon_code: "dfsdfsd",
				shop: "ciao",
				buyer_name: "abc",
				buyer_surname: "fgh",
				payed: "24",
				bonus: "30",
				orderId: "fsdfs",
				purchase_date: "04-04-2020",
				use_date: "-",
				state: "AVAIL",
			});
		}, 1000);
	});*/
};

export const redeemCoupon = async (couponInfo) => {
	trackCouponRedeem({ coupon_code: couponInfo.coupon_code });
	return Axios.post(REDEEM_COUPON_URL, couponInfo)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			trackCouponRedeemError({ coupon_code: couponInfo.coupon_code });
			return errorHandler(error);
		});

	/*return new Promise((resolve, reject) => {
		setTimeout(()=>{
			resolve({
				shop_code: 'fsdfds',
				coupon_code: 'dfsdfsd',
                shop: 'ciao',
                buyer_name:'abc',
                buyer_surname:'fgh',
                payed:'24',
				bonus:'30',
				orderId: 'fsdfs',
				purchase_date: '04-04-2020',
				use_date: '-',
                state: 'REDEEMED'
        })
		},2000)
		reject({ status: "ERROR", msg: "Coupon già Utilizzato" });
	});*/
};

export const postComment = async (values) => {
	return Axios.post(COMMENT_POST, values)
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);

	/*return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve({ wallMessageId: "ID_MESSAGE" });
		}, 2000);
	});*/
};

export const getComments = async (page = 0) => {
	return Axios.get(COMMENTS_LIST, { params: { page: page } })
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);

	/*return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve({
				messages: [
					{
						title: "Tiolo Clusu Message",
						message: "Messaggio di Prova I <3 Clusu",
						name: "Anonimo",
					},
				],
				pageInfo: { page: 0, lastPage: 10 }
			});
		}, 500);
	});*/
};

export const validateDiscount = async (
	discountCode,
	shopCode,
	amountIndex = null,
	amount = null
) => {
	console.log("shopCode", shopCode);

	return Axios.post(DISCOUNT_CHECK_URL, {
		promoCode: discountCode,
		shopId: shopCode,
		amountIndex: amountIndex,
		amount: amount,
	})
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);

	/*return new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
		if (discountCode === "12345")
			return { status: "OK", msg: "Codice corretto", discountValue: 20 };
		else if (discountCode === "12346")
			return {
				status: "WARNING",
				msg: "Codice non utilizzabile",
				html:
					"<p>\
		Non è possibile utilizzare il buon per il negozio\
		selezionato.\
		<br /> I buoni sconto non possono essere usati per le\
		seguenti cateogrie di negozi:\
		<ul>\
			<li>Bar</li>\
			<li>Ristoranti</li>\
		</ul>\
	</p>",
			};
		else return { status: "ERROR", msg: "Codice Errato" };
	});*/
};

/*const menuApiResponse = {
	type: "Ristorante",
	name: "Da Mario",
	description: "Una descrizione del posto",
	openings: "6-10 Lunedì",
	img:
		"https://clusu.blob.core.windows.net/shops/BarPasticceriaMantegazza.jpg",
	courses: [
		{
			name: "PANINI",
			sub_categories: [
				{
					name: null,
					dishes: [
						{
							name: "Ghiottone",
							description: null,
							price: "€ 7",
							ingredients:
								"Fontina - salsa tonnata - prosciutto cotto - insalata verde",
						},
					],
				},
				{
					name: "Piadine",
					dishes: [
						{
							name: "Stracchino - rucola fresca - crudo",
							description: null,
							price: "€ 8,50",
							ingredients: null,
						},
						{
							name: "Nutella",
							description: null,
							price: "€ 9.50",
							ingredients: "Nutella",
						},
					],
				},
				{
					name: "Toast",
					dishes: [
						{
							name: "Liscio",
							description: null,
							price: "€ 10.00",
							ingredients: null,
						},
					],
				},
			],
		},
		{
			name: "GELATERIA",
			sub_categories: [
				{
					name: "Coppe Gelato",
					dishes: [
						{
							name: "Coppa base",
							description: "Tre gusti gelato - panna montata",
							price: "€ 4,00",
							ingredients: null,
						},
						{
							name: "Coppa meringa",
							description:
								"Gelato alle crème - meringhe - panna montata",
							price: "€ 5,00",
							ingredients: null,
						},
					],
				},
				{
					name: "Affogati",
					dishes: [
						{
							name: "Al caffè",
							description:
								"Con gelato alle crème e panna montata",
							price: "€ 5,50",
							ingredients: null,
						},
					],
				},
				{
					name: null,
					dishes: [
						{
							name: "Note",
							description: null,
							price: null,
							ingredients: "Supplemento 1€ al tavolo",
						},
					],
				},
			],
		},
	],
};*/

export const getMenu = async (resturant) => {
	return Axios.get(GET_MENU + resturant, {
		params: {
			version: 2,
		},
	})
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);

	/*return new Promise((resolve, reject) => {
		//reject({ status: "ERROR", msg: "errore generico" })
		setTimeout(() => {
			resolve(menuApiResponse);
		},2000);
	});*/
};

export const uploadMenu = async (shop_code, file, onUploadProgress) => {
	const data = new FormData();
	data.append("menu_file", file);

	return Axios.post(UPLOAD_MENU, data, {
		params: {
			shop_code: shop_code,
		},
		onUploadProgress: onUploadProgress,
	})
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);
};

export const sendPurchaseComplete = async (orderId, paypalOrderId) => {
	const data = { orderId: orderId, paypalOrderId: paypalOrderId };

	return Axios.post(PURCHASE_COMPLETE_URL, data).then(
		(response) => response.data
	);

	/*console.log(data)
	new Promise(resolve=>setTimeout(resolve,3000)).then(()=>{
		return {"status":"OK"}
	})*/
};

const faqList = [
	{
		title: "Cos'è l'iniziativa 321..CON TE RIPARTIAMO?",
		description:
			"321.. CON TE RIPARTIAMO è un’iniziativa pensata dell'Amministrazione Comunale per ringraziare i Cittadini da 0 a 18 anni per il loro impegno nel rispettare le regole durante il lockdown per l'emergenza COVID-19: l'idea è quella di regalare un voucher del valore di 50€ da spendere presso le attività del Comune di Clusone, con l'obiettivo di contribuire anche a sostenere e rilanciare il commercio locale. All'interno di ogni voucher, è contenuto un codice promozionale personale che può essere utilizzato sulla piattaforma CluSU dove sono presenti tutte le attività che liberamente e gratuitamente hanno aderito (la lista è in costante costante aggiornamento)",
	},
	{
		title: "Cosa è la piattaforma CluSU?",
		description:
			"CluSU è una piattaforma web ideata e sviluppata da 5 ragazzi durante l'emergenza COVID-19: l'idea è nata per aiutare e dare un sostegno economico immediato agli esercenti di Clusone attraverso l'acquisto di buoni utilizzabili successivamente dai clienti al termine del lockdown. La piattaforma, gratuita sia per le attività che per gli utenti, ha ricevuto da subito un ottimo riscontro (con 2000€ raccolti nella prima settimana) ed è cresciuta rapidamente (ad oggi sono 60 le attività che hanno aderito): per questo motivo e per i numerosi messaggi di supporto, abbiamo deciso di mantere attiva la piattaforma e continuare a migliorarla con l'obiettivo di sostenere ed incentivare il commercio di una delle più belle cittadine della Val Seriana.",
	},
	{
		title: "Cosa è il Clusù?",
		description:
			"Il Clusù è il buono che viene acquistato sulla piattaforma: confermato l'ordine, riceverai una email con indicati i codici e il qr code necessari per riscattare il tuo buono (Clusù) presso l'attività selezionata. Il Clusù ha come valore l'importo da te selezionato (e può essere) maggiorato di una percentuale offerta liberamente dall'attività come segno di ringraziamento per la tua fiducia. Ogni attività stabilisce inoltre delle condizioni di utilizzo e di validità del buono.",
	},
	{
		title:
			"Il codice promozionale può essere utilizzato per acquistare un Clusù presso tutte le attività presenti sulla piattaforma?",
		description:
			"Il Comune di Clusone ha deciso che i codici promozionali dell'iniziativa non sono utilizzabili per l'acquisto di Clusù presso le attività di somministrazioni bevande: durante la verifica del codice promozionale in fase di acquisto, un messaggio vi comunicherà che il codice non è valido.",
	},
	{
		title: "Ci sono altre restrizioni?",
		description:
			"Ogni attività può stabilire delle condizioni di utilizzo del buono: queste condizioni sono visibili durante la fase di acquisto e nell'email di conferma.",
	},
	{
		title: "Da quando è possibile utilizzare il codice promozionale?",
		description: "Il codice promozionale sarà utilizzabile dal 15 Luglio 2020.",
	},
	{
		title:
			"Il codice promozionale dev'essere consumato tutto in un unico acquisto?",
		description:
			"No, abbiamo predisposto la piattaforma in modo tale da permettere di utilizzare il codice promozionale per diversi acquisti, fino al raggiungimento del valore totale dello stesso (50€).",
	},
	{
		title:
			"Posso acquistare un Clusù per un importo superiore al valore del mio codice promozionale?",
		description:
			"Si, puoi selezionare l'importo che desideri e quando inserirai il tuo codice promozionale in automatico il sistema calcolerà l'eventuale valore che dovrai pagare sottraendo all'importo selezionato il valore del tuo codice: se il risultato è 0, potrai completare immediatamente l'ordine, altrimenti il sistema ti guiderà per completare l'acquisto e pagare la differenza attraverso il circuito PayPal, una carta prepagata o bancomat. E' tutto molto semplice e sicuro!",
	},
	{
		title: "Quando scade il mio codice promozionale?",
		description:
			"Il Comune di Clusone ha fissato al 30 settembre 2020 la scadenza del codice promozionale. Ciò significa che entro le 23:59 del 30 Settembre 2020 sarà possibile utilizzare il codice promozionale per effettuari acquisti sulla piattaforma, mentre allo scadere di tale data il codice promozionale sarà invalidato senza alcuna possibilità di proroga.",
	},
	{
		title: "Quando scade invece il Clusù che ho acquistato?",
		description:
			"Una volta acquistato il Clusù, NON è obbligatorio utilizzarlo entro il 30 Settembre 2020: ogni attività stabilisce in maniera autonoma e libera il periodo di validità del buono. Entro tale data dovrete recarvi fisicamente presso l’attività per ritirare la merce od i prodotti acquistati. La scadenza di ogni buono è visibile durante l'acquisto e nell'email di conferma.",
	},
	{
		title:
			"Perchè il valore del mio Clusù varia a seconda dell'attività selezionata?",
		description:
			"Come per la data di scadenza e le condizioni di validità del buono, ogni attività stabilisce liberamente una percentuale di maggiorazione (da 0 al 25%) che verrà applicata all'importo da te selezionato.",
	},
	{
		title:
			"Non trovo l’attività di Clusone dove vorrei utilizzare il mio codice promozionale, cosa posso fare?",
		description:
			"Se tra le attività che hanno aderito non trovi quella che vorresti, avvisala dell'iniziativa, invitala ad iscriversi o scrivici a info@clusu.com: l'adesione è gratutita per tutti ed è un ottima vetrina!",
	},
];

export const getRipartiamoFAQ = async () => {
	return Axios.get(RIPARTIAMO_FAQ_URL).then(
		(response) => {
			return response.data;
		},
		() => {
			return faqList;
		}
	);
};

export const getRipartiamoCounters = async () => {
	return Axios.get(RIPARTIAMO_COUNTERS_URL)
		.then((response) => {
			return response.data;
		})
		.catch(errorHandler);
};

export const discountCodeGetInfo = async (discountCode) => {
	return Axios.get(DISCOUNT_INFO_CODE_URL + discountCode)
		.then((response) => response.data)
		.catch(errorHandler);

	/*return new Promise((resolve,reject)=>setTimeout(resolve,2000)).then(()=>{
		return {
			expiration: "31/12/2020",
			value: 20,
			purchases: [
				{
					date: "05/07/2020",
					shop: "Mantegazza",
					amount: 15,
				},
			],
		}
	}).catch(errorHandler)*/
};
