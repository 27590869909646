import React from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import CustomInputFieldError from "./CustomInputFieldError";
import { couponCodeRegex } from "../Services/Globals";

export default function GetCouponForm(props) {
	const initValues = {
		coupon_code: "",
	};

	const validationSchema = yup.object().shape({
		coupon_code: yup
			.string()
			.matches(couponCodeRegex, "Codice non valido")
			.required("Richiesto"),
	});

	const initialValues = Object.assign(
		{},
		initValues,
		props.initialValues.coupon_code
			? { coupon_code: props.initialValues.coupon_code }
			: null
	);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={props.onSearchCoupon}
			validateOnMount={true}
			enableReinitialize={true}>
			<Form>
				<fieldset>
					<div className="form-group">
						<label>
							Codice coupon
							<span className="required-field">*</span>
						</label>
						<CustomInputFieldError
							name="coupon_code"
							className="form-control"
							placeholder="Codice coupon"
						/>
					</div>
				</fieldset>

				<Field>
					{({ field, form }) => {
						return (
							<button
								{...field}
								type="submit"
								className="btn btn-primary"
								disabled={!form.isValid}>
								Verifica coupon
							</button>
						);
					}}
				</Field>
			</Form>
		</Formik>
	);
}
