import React from 'react'
import PropTypes from 'prop-types'
import cn from "classnames"

function CategoryButton({ categoryName, filter, selectCategory, value }) {
    return (
        <button
			className={cn("category-selector-button", {
				selected: value === filter,
			})}
			onClick={() => {
				selectCategory(value);
			}}>
			{categoryName}
		</button>
    )
}

CategoryButton.propTypes = {
    categoryName: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    selectCategory: PropTypes.func.isRequired
}

export default CategoryButton

