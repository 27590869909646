import React, { useState } from "react";
import "./redeem.scss";
import GetCouponForm from "../Components/GetCouponForm";
import Navbar from "../Components/NavBar";
import CouponCheck from "./CouponCheck";
import Alert from "../Components/Alert";

import * as AppStates from "../Services/AppStates";
import { checkCoupon, redeemCoupon } from "../Services/Api";
import RedeemForm from "../Components/RedeemForm";
import { useLocation } from "react-router-dom";

function Loader(props) {
	if (props.appState === AppStates.COUPON_LOADING)
		return (
			<div className="text-center">
				<div className="spinner-grow text-primary" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	else return <>{props.children}</>;
}

function getCodesFromQuery(query) {
	const queryParams = new URLSearchParams(query);

	const coupon_code = queryParams.get("coupon_code");
	const coupon_secret_code = queryParams.get("coupon_secret_code");

	return {
		coupon_code: coupon_code,
		coupon_secret_code: coupon_secret_code,
	};
}

export default function RedeemPage(props) {
	//get coupon code from query params
	var queryParams = getCodesFromQuery(useLocation().search);

	const [formState, setFormState] = useState(queryParams);
	const [appState, setAppState] = useState(AppStates.INIT_STATE);
	const [coupon, setCoupon] = useState(null);
	const [alert, setAlert] = useState({ visible: false, status: "", msg: "" });

	function onSearchCoupon(values) {
		setFormState((oldValues) => {
			return { ...oldValues, ...values };
		});
		setAppState(AppStates.COUPON_LOADING);

		async function getCoupon() {
			try {
				setAlert({ visible: false });
				var coupon = await checkCoupon(values);
				setCoupon(coupon);
				setAppState(AppStates.COUPON_CHECK_STATE);
				window.scrollTo({ top: 0, behavior: "smooth" });
			} catch (e) {
				setAppState(AppStates.COUPON_ERROR_STATE);
				setAlert({ visible: true, ...e });
				window.scrollTo({ top: 0, behavior: "smooth" });
			}
		}

		getCoupon();
	}

	function onRedeem(values) {
		setAppState(AppStates.COUPON_LOADING);

		async function redeem() {
			try {
				var response = await redeemCoupon(values);
				setAppState(AppStates.COUPON_REEDEMED_STATE);
				setCoupon((coupon) => {
					return Object.assign({}, coupon, response);
				});
				setAlert({
					visible: true,
					status: "OK",
					msg: "Coupon utilizzato correttamente",
				});
				window.scrollTo({ top: 0, behavior: "smooth" });
			} catch (e) {
				setAppState(AppStates.COUPON_ERROR_STATE);
				setAlert({ visible: true, ...e });
				window.scrollTo({ top: 0, behavior: "smooth" });
			}
		}

		redeem();
	}

	function closeAlert() {
		setAlert((old) => {
			return { ...old, visible: false };
		});
	}

	return (
		<>
			<Navbar />

			<section className="clusu-content">
				<div className="container">
					{alert.visible && (
						<div className="row justify-content-center">
							<div className="col-md-8">
								<Alert alert={alert} onCloseAlert={closeAlert} />
							</div>
						</div>
					)}

					<div className="row justify-content-center">
						<div className="col-md-8">
							{/* serach form */}
							<div className="card clusu-card redeem-search-coupon">
								<div className="card-header">
									Recupera le informazioni del coupon
								</div>
								<div className="card-body">
									<Loader appState={appState}>
										<GetCouponForm
											onSearchCoupon={onSearchCoupon}
											initialValues={formState}
											appState={appState}
										/>

										{appState !== AppStates.INIT_STATE && coupon ? (
											<CouponCheck coupon={coupon} />
										) : null}
									</Loader>
								</div>
							</div>
						</div>

						{appState !== AppStates.INIT_STATE &&
							appState !== AppStates.COUPON_LOADING &&
							coupon &&
							coupon.state === "AVAIL" && (
								<div className="col-md-8">
									<div className="card clusu-card">
										<div className="card-header">Usa il coupon</div>
										<div className="card-body">
											<RedeemForm
												coupon={coupon}
												initialValues={formState}
												onRedeem={onRedeem}
												appState={appState}
											/>
										</div>
									</div>
								</div>
							)}
					</div>
				</div>
			</section>
		</>
	);
}
