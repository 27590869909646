import React from "react";
import { Field, ErrorMessage } from "formik";
import cn from 'classnames';
import PropTypes from 'prop-types';


export function ErrorComponent(props) {
	return <div className="invalid-feedback">{props.children}</div>;
}

export function CustomInputField({ field, form, ...props }) {
	var style = cn(props.className ? props.className : "form-control",{
		'is-invalid': form.touched[field.name] && form.errors[field.name] 
	})


		/*"form-control " +
		(form.touched[field.name] && form.errors[field.name]
			? "is-invalid"
			: "");*/
	const inputType = props.inputType ? props.inputType : 'input';
	const newProps = {...field,...props,className:style};
	delete newProps.inputType

	return React.createElement(inputType,newProps)
	//return <input {...field} {...props} className={style} />;
}

export default function CustomInputFieldError(props) {
	return (
		<>
			<Field component={CustomInputField} {...props} />
			<ErrorMessage name={props.name} component={ErrorComponent} />
		</>
	);
}

CustomInputFieldError.propTypes = {
	name: PropTypes.string.isRequired,
	inputType: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string
}