import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ShopSelector } from "./Shops";
import { getShopList } from "../Services/Api";
import * as AppStates from "../Services/AppStates";
import PurchaseSidebar from "./PurchaseSidebar";
import { trackCheckoutEvent } from "../AppInsight";
import { calcFinalPayment } from "../Services/Globals";
//import CategoryButton from "./CategoryButton";
import cn from "classnames";

const initState = {
	shopId: null,
	amount: { payment: 0, value: 0, discountValue: 0 },
	name: null,
	surname: null,
	mail: null,
	shop: null,
	amountIndex: "",
	discountCode: "",
};

const FILTER_ALL = "ALL";
const HOME_DELIVERY = "Home_Delivery";
const TAKE_AWAY = "Take_away";

function PurchaseController() {
	const [shopList, setShopList] = useState([]);
	const [categoryFilter, setCategoryFilter] = useState({
		categories: [],
		filter: FILTER_ALL,
	});
	const [formData, setFormData] = useState({ ...initState });
	const [appState, setAppState] = useState(AppStates.INIT_STATE);
	const [searchFilter, setSearchFilter] = useState("");
	const [serviceFilter, setserviceFilter] = useState(FILTER_ALL);
	const [contests, setContests] = useState([]);
	const [contestFilter, setContestFilter] = useState(FILTER_ALL);

	/**
	 * Fetch shops and categories from server
	 */
	useEffect(() => {
		const getList = async () => {
			try {
				const { list, categories, contests } = await getShopList(); //TODO: implement a cancellation mechanism
				setShopList(list);
				setCategoryFilter((oldFilter) => {
					//set categories
					return { ...oldFilter, categories: categories };
				});
				setContests(contests);
			} catch (e) {
				console.error("Error fetching shops list", e);
			}
		};

		getList();
	}, []); //execute function on component load

	/**
	 * Callback function used to select shop
	 */
	const onShopSelection = useCallback(
		(id) => {
			//if we are in checkout mode, avoid click
			if (appState !== AppStates.INIT_STATE) return;

			//set shop information on form
			setFormData((old) => {
				var selected = null;
				if (old.shopId === id) selected = null;
				else selected = id;

				return {
					...old,
					shopId: selected,
					shop: shopList.find((v) => v.id === selected),
					amount: "",
					amountIndex: "",
				};
			});
		},
		[appState, shopList]
	); //bind new function if appState change or new shop list is available

	/**
	 * Start checkout process
	 */
	const onCheckout = useCallback((values) => {
		setAppState(AppStates.CHECKOUT_STATE);

		let checkoutData = undefined;

		setFormData((oldData) => {
			let amount = { ...oldData.shop.amounts[values.amountIndex] }; //get the amount eg:{ payment: 5, value: 5.1 }
			amount.payment = calcFinalPayment(
				//set real payment
				amount.payment,
				values.discountValue
			);
			amount.discountValue = values.discountValue; //set discount value eg: 2
			return (checkoutData = { ...oldData, ...values, amount: amount });
		});

		trackCheckoutEvent({
			shopId: checkoutData.shopId,
			amount: checkoutData.amount.payment,
		});
	}, []); //create this function on component mount

	/**
	 * restart purchase process
	 */
	const onRestart = useCallback(() => {
		setAppState(AppStates.INIT_STATE);
		setFormData({ ...initState });
	}, []);

	/**
	 * Set current category filter value
	 */
	const selectCategory = useCallback((category) => {
		setCategoryFilter((oldFilter) => {
			return { ...oldFilter, filter: category };
		});
	}, []);

	/**
	 * Generate the categories buttons only when new categories
	 * are available or the selected category has changed
	 */
	const categoryList = useMemo(() => {
		return categoryFilter.categories.map((category) => {
			return (
				<option value={category.name} key={category.name}>
					{category.name}
				</option>
			);
		});
	}, [categoryFilter.categories]);

	/**
	 * Select delivery service to filter on
	 */
	const selectService = useCallback((serviceType) => {
		setserviceFilter(serviceType);
	}, []);

	/**
	 * produce options for contests selection
	 */
	const contestsOptions = useMemo(
		() =>
			[<option value={FILTER_ALL} key={"ALL"}>Tutte le iniziative</option>].concat(
				contests.map((c) => (
					<option key={c.id} value={c.id}>
						{c.name}
					</option>
				))
			),
		[contests]
	);

	/**
	 * Return if the shop is compatible with the selected service filter
	 */
	const checkServiceType = useCallback((shop, filter) => {
		if (shop.homeDelivery && filter === HOME_DELIVERY) return true;
		if (shop.takeAway && filter === TAKE_AWAY) return true;
		return false;
	}, []);

	/**
	 * Generate current visible shops based on selected category
	 * List is generated only if the category filter change or new shop list are available
	 */
	const visibleList = useMemo(() => {
		return shopList.filter(
			(shop) =>
				//apply filter based on category if search field is empty
				(searchFilter === "" &&
					(categoryFilter.filter === FILTER_ALL ||
						shop.category === categoryFilter.filter) &&
					(serviceFilter === FILTER_ALL ||
						checkServiceType(shop, serviceFilter)) &&
					(contestFilter === FILTER_ALL ||
						shop.contest.includes(contestFilter))) ||
				//if serach field is filled, serach by name
				(searchFilter !== "" &&
					shop.name.toLowerCase().includes(searchFilter))
		);
	}, [
		categoryFilter.filter,
		shopList,
		searchFilter,
		serviceFilter,
		checkServiceType,
		contestFilter,
	]);

	/**
	 * set search filter
	 */
	const onSearchChange = useCallback((e) => {
		setSearchFilter(e.currentTarget.value.toLowerCase());
	}, []);

	return (
		<section id="purchase">
			<div className="row">
				<div className="col-12">
					<div className="input-group clusu-rounded">
						<input
							type="search"
							className="form-control"
							placeholder="Cerca un negozio"
							value={searchFilter}
							onChange={onSearchChange}
						/>
						<div className="input-group-append">
							<span className="input-group-text">
								<i className="icofont-search-1"></i>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="row">

				<div className="col-12 category-selector">
					<form className={cn({ hidden: searchFilter !== "" })}>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label>Filtra per categoria:&nbsp;</label>
								<select
									className="custom-select form-control"
									value={categoryFilter.filter}
									onChange={(e) => {
										selectCategory(e.target.value);
									}}>
									<option value={FILTER_ALL}>
										Tutte le categorie
									</option>
									{categoryList}
								</select>
							</div>

							<div className="form-group col-md-6">
								<label>Filtra per servizio:&nbsp;</label>
								<select
									className="custom-select form-control"
									onChange={(e) => {
										selectService(e.target.value);
									}}>
									<option value={FILTER_ALL}>
										Tutti i servizi
									</option>
									<option value={TAKE_AWAY}>Take away</option>
									<option value={HOME_DELIVERY}>
										Consegna a domicilio
									</option>
								</select>
							</div>
						</div>

						<div className="form-row">
							<div className="form-group col-12">
								<label>
									Filtra per partecipazione a iniziative:
								</label>
								<select
									className="custom-select form-control"
									value={contestFilter}
									onChange={(e) => {
										setContestFilter(e.target.value);
									}}>
									{contestsOptions}
								</select>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div className="row">
				<ShopSelector
					onClick={onShopSelection}
					list={visibleList}
					selected={formData.shopId}
					appState={appState}
					isLoading={shopList.length === 0}
					categoryFilter={categoryFilter}
				/>

				<PurchaseSidebar
					disabled={formData.shopId === null}
					data={formData}
					onCheckout={onCheckout}
					appState={appState}
					setAppState={setAppState}
					onRestart={onRestart}
				/>
			</div>
		</section>
	);
}

export default PurchaseController;
