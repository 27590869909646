import React from "react";
import logo from "../img/logo.svg";
import ipad from "../img/ipad.png";
import PurchaseController from "../Components/PurchaseController";
//import { Link } from "react-router-dom";

import appInsightConnect from "../AppInsight";

function Intro() {
	const goToPurchase = () => {
		document
			.getElementById("purchase")
			.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<header id="intro">
			<div className="container">
				<div className="bg-gradient"></div>

				<div id="logo">
					<img src={logo} alt="logo" />
				</div>

				<div id="intro-content">
					<p>
						Sosteniamo ed
						<br /> incentiviamo il
						<br /> commercio nelle
						<br /> attività della nostra
						<br /> <b>cittadina</b>
					</p>

					<div>
						<button onClick={goToPurchase} className="btn">
							ACQUISTA ORA!
						</button>
						{/*<Link to="/buoni-spesa" className="btn white-button">
							Buoni spesa
						</Link>*/}
					</div>
				</div>
			</div>

			<div id="mouse-scroll">
				<span>
					<i className="icofont-scroll-down"></i>
				</span>
			</div>
		</header>
	);
}

function HowItWors() {
	return (
		<section className="info-section">
			<div className="row">
				<section className="col-12">
					<div className="info-header">
						<span className="bottom-line">
							COS&rsquo;&nbsp;&Egrave;
						</span>
					</div>

					<p className="info-content" style={{ textAlign: "center" }}>
						È la piattaforma che consente di{" "}
						<b>aiutare e supportare</b> <br />
						gli esercenti della nostra cittadina.
					</p>
				</section>
			</div>
			<div className="row">
				<section className="col-12">
					<div
						className="info-header"
						style={{ marginBottom: "3.5vh" }}>
						<span className="bottom-line">COME FUNZIONA</span>
					</div>

					<div className="row">
						<div className="col-md-6 coupon-example">
							<img src={ipad} alt="coupon" />
						</div>

						<div className="col-md-6 howto-side">
							<p className="info-content">
								Semplice! Ricerca e scegli l'attività, seleziona
								l'importo che desideri e paga in tutta
								sicurezza: in poco tempo riceverai tramite email
								il tuo <b>Clusù!</b>
							</p>

							<p className="info-content">
								Il Clusù è un buono che ha come valore l’importo
								pagato <b>maggiorato</b> di una percentuale
								offerta dall’esercente, come simbolo di
								gratitudine per la tua fiducia; puoi comprare o
								regalare tutti i Clusù che desideri!
							</p>

							{/*<p className="info-content">
								Con il tuo sostegno consentirai ai piccoli
								imprenditori di ricevere subito <b>liquidità</b>{" "}
								per coprire le spese in questo periodo di
								emergenza economica o semplicemente di avere{" "}
								<b>continuità</b> nel loro credito grazie a
								clienti <b>affezionati</b> come te.
							</p>*/}
						</div>
					</div>
				</section>
			</div>
		</section>
	);
}

function MainPurchase() {
	return (
		<>
			<Intro />

			<div className="container">
				<HowItWors />

				<PurchaseController />
			</div>
		</>
	);
}

export default appInsightConnect(MainPurchase);
