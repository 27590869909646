import React, { Component } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import Navbar from "../Components/NavBar";
import CustomInputFieldError, {
	ErrorComponent,
} from "../Components/CustomInputFieldError";
import SubmitButton from "../Components/SubmitButton";
import { shopCodeRegex } from "../Services/Globals";
import * as yup from "yup";
import cn from "classnames";
import { uploadMenu } from "../Services/Api";
import Alert, { alertInitState } from "../Components/Alert";
import ModalMessage from '../Components/ModalMessage';
import clipboard from 'clipboard';
import { createRef } from "react";

const UploadForm = (props) => {
	const initialValues = {
		shop_code: "",
		menu_file: "",
	};

	const validationSchema = yup.object().shape({
		shop_code: yup
			.string()
			.matches(shopCodeRegex, "Codice negozio non valido")
			.required("Campo Richiesto"),
		menu_file: yup
			.mixed()
			.required("Campo Richiesto")
			.test("file-format", "Formato file non supportato", (values) => {
				if (values) {
					if (
						values.type ===
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					)
						return true;
				}
				return false;
			})
			.test("file-size", "Superata dimensione massima file", (values) => {
				if (values) {
					if (values.size < 2000000)
						//max 2MB
						return true;
				}
				return false;
			}),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnMount={true}
			onSubmit={props.onSubmit}>
			{(formikProps) => (
				<form
					onReset={formikProps.handleReset}
					onSubmit={formikProps.handleSubmit}>
					<fieldset disabled={formikProps.isSubmitting}>
						<div className="form-group">
							<label htmlFor="shop_code">
								Codice negozio{" "}
								<span className="required-field">*</span>
							</label>
							<CustomInputFieldError
								name="shop_code"
								id="shop_code"
								placeholder="Codice negozio"
							/>
						</div>

						<div className="custom-file mb-3">
							<Field name="menu_file">
								{({ field, form, meta }) => {
									return (
										<>
											<input
												type="file"
												name={field.name}
												id="menu_file"
												accept=".xlsx"
												className={cn(
													"custom-file-input",
													{
														"is-invalid":
															meta.touched &&
															meta.error,
													}
												)}
												onChange={(e) => {
													form.setFieldValue(
														field.name,
														e.currentTarget.files[0]
													);
												}}
												onBlur={field.onBlur}
											/>
											<label
												htmlFor="menu_file"
												className="custom-file-label ">
												{field.value.name ? (
													field.value.name
												) : (
													<span>
														Seleziona il file&nbsp;
														<span className="required-field">
															*
														</span>
													</span>
												)}
											</label>
										</>
									);
								}}
							</Field>
							<ErrorMessage
								name="menu_file"
								component={ErrorComponent}
							/>
						</div>

						<SubmitButton>
							Carica menu&nbsp;
							{formikProps.isSubmitting && (
								<span
									className="spinner-border spinner-border-sm"
									role="status"></span>
							)}
						</SubmitButton>
					</fieldset>
				</form>
			)}
		</Formik>
	);
};

const ProgressBar = (props) => {
	return (
		<div className="progress">
			<div
				className="progress-bar"
				role="progressbar"
				style={{ width: props.progress + "%" }}>
				{props.progress} %
			</div>
		</div>
	);
};

export default class MenuUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			progress: 0,
			alert: alertInitState,
			link: false
		};
		this.copyButton = createRef();
	}

	getSnapshotBeforeUpdate(){
		if(this.clipboard){
			console.log("cleanup")
			this.clipboard.destroy();
		}
		return null;
	}

	componentDidUpdate(){
		console.log("ref",this.copyButton.current)
		if(this.copyButton.current)
			this.clipboard = new clipboard(this.copyButton.current)
	}

	onProgress = (progress) => {
		var perc = Math.ceil((progress.loaded / progress.total) * 100);
		this.setState({
			progress: perc,
		});
	};

	onSubmit = async (values, formikBag) => {
		this.setState({
			alert: {...alertInitState, visible:false}
		})
		try {
			const link = await uploadMenu(
				values.shop_code,
				values.menu_file,
				this.onProgress
			);
			formikBag.resetForm();
			this.setState({
				progress: 0,
				alert: {
					visible: true,
					status: "OK",
					msg: "Caricamento completato correttamente",
				},
				link: link
			});
		} catch (e) {
			this.setState({
				alert: { visible: true, ...e },
			});
		}
	};

	closeModal = ()=>{
		this.setState({
			link: false
		})
	}

	render() {
		return (
			<>
				<Navbar />

				<ModalMessage visible={this.state.link !== false} title="Il tuo menù" close={this.closeModal}>
					<p>Puoi vedere il tuo menù <a href={this.state.link}>qui</a></p>
					<p>Qui sotto trovi l'indirizzo permanente a cui puoi trovare il tuo menù</p>
					<div className="input-group">
						<input type="text" id="menu-link" className="form-control" readOnly value={this.state.link}/>
						<div className="input-group-append">
							<button className="btn btn-outline-dark" data-clipboard-target="#menu-link" ref={this.copyButton}>Copia</button>
						</div>
					</div>
				</ModalMessage>

				<section className="clusu-content">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-8">
								<Alert alert={this.state.alert} dismissable={false}/>

								<div className="card clusu-card">
									<div className="card-header">
										Carica il tuo menu
									</div>
									<div className="card-body">
										<UploadForm onSubmit={this.onSubmit} />

										<ProgressBar
											progress={this.state.progress}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}
