import React from "react";
import Navbar from "../Components/NavBar";

export default function Legal() {
	return (
		<>
			<Navbar />
			<div className="container">
				<section className="content">
					<p>
						<strong>Note Legali piattaforma ClUs&ugrave;</strong>
					</p>
					<p>&nbsp;</p>
					<p>
						La navigazione e l&rsquo;utilizzo del sito web:{" "}
						<a href="http://www.clusu.com">www.clusu.com</a>{" "}
						comportano l&rsquo;accettazione integrale delle presenti
						Condizioni Generali di utilizzo del Sito e dei singoli
						servizi e/o contenuti per esso forniti. L&rsquo;utente
						dichiara di aver letto, compreso e di accettare le
						presenti Condizioni Generali di utilizzo.
					</p>
					<p>&nbsp;</p>
					<p>
						<strong>Di cosa si tratta?</strong>
					</p>
					<p>
						Abbiamo realizzato una piattaforma che consente ai
						cittadini, di supportare in 
						questo momento di difficolt&agrave; gli esercenti
						presenti nel comune di Clusone.
					</p>
					<p>
						Ogni cittadino ha cos&igrave; la possibilit&agrave; di
						acquistare un buono (il CluS&Uacute;) selezionando tra
						le 
						attivit&agrave; commerciali che prenderanno parte
						al
						nostro progetto.
					</p>
					<p>&nbsp;</p>
					<p>
						<strong>Come funziona?</strong>
					</p>
					<p>
						L&rsquo;iscrizione alla piattaforma &egrave; gratuita
						sia per il cittadino sia per le attivit&agrave;
						commerciali
					</p>
					<p>
						Gli esercenti che aderiranno al progetto saranno quindi
						inseriti sulla piattaforma e ad essi si riferiranno i
						buoni acquistabili.
					</p>
					<p>
						Per incentivare gli acquisti dei buoni poi spendibili
						successivamente nel negozio prescelto, all&rsquo;atto
						della domanda di iscrizione verr&agrave; richiesto ai
						commercianti di applicare una piccola maggiorazione
						aggiuntiva al valore buono effettivamente corrisposto
						(es. Buono acquistato dell&rsquo;importo di &euro;50 +
						maggiorazione del 10% = &euro;55). Gli importi pensati
						per i singoli buoni sono di
						&euro;20/&euro;50/&euro;70/&euro;100.
					</p>
					<p>
						Una volta inseriti gli esercenti sul portale ogni utente
						potr&agrave; scegliere l&rsquo;attivit&agrave;
						commerciale per cui acquistare il buono.
					</p>
					<p>
						A seguito di ciascun acquisto al commerciante
						interessato arriver&agrave; un&rsquo;email contenente il
						riepilogo della transazione. Ciascun buono potr&agrave;
						essere utilizzato una sola volta grazie ad
						un&rsquo;efficace sistema di validazione.
					</p>
					<p>
						<strong>Sistema di validazione</strong>
					</p>
					<p>
						Ad acquisto effettuato, verr&agrave; creato un coupon
						con un codice identificativo, che verr&agrave; inviato
						sia all'utente che all&rsquo;esercente (a cui il buono
						fa riferimento).
					</p>
					<p>
						Al cliente verr&agrave; inviato un ulteriore codice
						&ldquo;unico e segreto&rdquo; a 8 cifre (anch&rsquo;esso
						presente sul buono).
					</p>
					<p>
						L&rsquo;esercente ricever&agrave; un PIN che identifica
						la sua attivit&agrave; e con il quale potr&agrave;
						validare ogni buono riscattato dai clienti.
					</p>
					<p>
						Il processo di validazione inizia con la scansione del
						buono, (o con l'inserimento del numero identificativo),
						da parte dell&rsquo;esercente nella pagina dedicata,
						successivamente tramite comunicazione del cliente del
						codice segreto e il conseguente inserimento, il buono
						sar&agrave; validato
					</p>
					<p>
						<strong>&nbsp;</strong>
					</p>
					<p>
						<strong>Pagamenti</strong>
					</p>
					<p>
						I pagamenti saranno gestiti con il circuito PayPal
						sfruttando l&rsquo;appoggio di un conto intestato a
						TurismoProClusone che provveder&agrave; a saldare, senza
						spese aggiuntive, gli importi dei buoni acquistati con
						tempistiche e modalit&agrave; che sono concordate con
						ogni attivit&agrave; commerciale all&rsquo;atto
						d&rsquo;iscrizione al progetto. Ad ogni buono
						dovr&agrave; corrispondere l&rsquo;emissione di regolare
						scontrino fiscale all&rsquo;utente quando
						spender&agrave; lo
						stesso.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</p>
					<p>
						La collaborazione a livello fiscale con
						TurismoProClusone e il conseguente utilizzo del c/c
						&egrave; possibile grazie alla{" "}
						<strong>RISOLUZIONE N.21/E</strong> con oggetto:{" "}
						<strong>
							Trattamento tributario ai fini IVA dei &ldquo;buoni
							acquisto o regalo&rdquo; concernente
							l&rsquo;interpretazione del DPR n. 633 del 1972.
						</strong>
						<br />
						Di seguito
						approfondimento: <br />
						<em>
							Come disposto nella risoluzione n.21/E del
							22/02/2011 &egrave; stato chiarito che i buoni
							/voucher per l'acquisto di beni o servizi sono
							considerati come semplici documenti di
							legittimazione che consentono di identificare
							l'avente diritto all'acquisto di un bene/servizio
							con la possibilit&agrave; di trasferire tale diritto
							senza osservare le regole sulla cessione di beni. In
							sostanza la cessione del bene non assume rilevanza
							ai fini dell'IVA.
						</em>
					</p>
					<p>
						<em>I rapporti che si creano in questo caso sono:</em>
					</p>
					<p>
						<strong>
							<em>
								1) RAPPORTO TRA ENTE EMITTENTE E ESERCIZIO
								CONVENZIONATO:
							</em>
						</strong>{" "}
						<em>
							Il rimborso del valore dei buoni da parte
							dell'emittente all'esercizio convenzionato
							costituisce un'operazione non rilevante ai fini IVA
							(art.2 comma 3 lett. a) DPR 633/1972
						</em>
					</p>
					<p>
						<strong>
							<em>
								2) RAPPORTO TRA ENTE EMITTENTE E BENEFICIARIO
								DEL BUONO:
							</em>
						</strong>{" "}
						<em>
							La distribuzione del buono al soggetto risulta
							anch'essa fuori campo IVA ( (art.2 comma 3 lett. a)
							DPR 633/1972 in quanto la circolazione dei titoli
							&egrave; una movimentazione di carattere finanziario
						</em>
					</p>
					<p>
						<strong>
							<em>
								3) RAPPORTO BENEFICIARIO DEL BUONO ED ESERCIZIO
								CONVENZIONATO
							</em>
						</strong>{" "}
						<em>
							L'esercizio commerciale convenzionato cede beni o
							presta servizi ai portatori del buono d'acquisto, la
							base imponibile sar&agrave; costituita dall'intero
							prezzo del bene e/o servizio che potr&agrave; essere
							uguale al valore nominale del buono o essere coperto
							in parte da questo.
						</em>
					</p>
					<p>
						<em>
							Al momento dell'effettuazione dell'operazione
							l'esercizio convenzionato dovr&agrave; emettere
							regolare scontrino/ fattura con IVA per l'intero
							prezzo del bene e/o servizio dovuto al consumatore
							finale<strong>, </strong>costituisce invece
							operazione rilevante ai fini IVA , per la quale
							dovr&agrave; essere emessa fattura il pagamento
							della commissione da parte dell'esercizio
							convenzionato a favore dell'ente emittente.
						</em>
					</p>
					<p>
						<strong>&nbsp;</strong>
					</p>
					<p>
						<strong>&nbsp;</strong>
					</p>
					<p>
						<strong>&nbsp;</strong>
					</p>
					<p>
						<strong>&nbsp;</strong>
					</p>
					<p>
						<strong>&nbsp;</strong>
					</p>
					<p>
						<strong>Protezione dei dati e Privacy</strong>
					</p>
					<ol>
						<li>
							I dati del privato saranno raccolti, trattati,
							conservati, diffusi e cancellati dal titolare del
							sito e dal Venditore secondo quanto disposto dalla
							legge italiana, nonch&eacute; dal Reg. U.E. n.
							679/2016
						</li>
						<li>
							L'esercente tratta i dati personali dei clienti in
							modo finalizzato alla stipula ed esecuzione del
							buono e nel rispetto delle normative vigenti in
							Italia.
						</li>
						<li>
							I dati personali richiesti per l&rsquo;iscrizione
							alla piattaforma sono: nome, cognome, e-mall
						</li>
						<li>
							I dati personali saranno trattati
							dall&rsquo;esercente con riservatezza e non saranno
							ceduti a terzi che non siano direttamente coinvolti
							nelle procedure di ordine o pagamento.
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</section>
			</div>
		</>
	);
}
