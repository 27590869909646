import React from "react";
import cn from "classnames";
import { CHECKOUT_STATE } from "../Services/AppStates";

export function ShopPanel(props) {
	return (
		<div
			className={"shop " + (props.selected ? "active" : "")}
			onClick={props.onClick}>
			<div
				className="shop-top-border"
				style={{ backgroundColor: props.topColor }}
			/>

			<div
				className="shop-image"
				style={{ backgroundImage: "url(" + props.image + ")" }}>
					{props.menuLink && <span className="menu-link">
						<a href={props.menuLink}>Vedi il menu</a>
					</span>}
				</div>

			<div
				className={cn(
					"shop-content d-flex flex-column justify-content-between",
					{ "shop-content-image": props.image }
				)}>
				<div className="flex-grow-1">
					{props.category && (
						<p
							className="shop-category-name"
							style={{ color: props.topColor }}>
							{props.category}
						</p>
					)}

					<div className="shop-name d-flex justify-content-between align-items-start">
						<span>{props.name}</span>
						{props.bonus !== '0%' && <span
							className={cn(
								"bonus-value-badge",
								"badge",
								"badge-pill",
								{
									"badge-success": !props.preview,
									"badge-danger": props.preview,
								}
							)}>
							+ {props.bonus}
						</span>}
					</div>
					<p className="shop-description flex-grow-1">
						{props.description}
					</p>
				</div>

				<div>
					<p
						className="shop-select-button"
						style={{ color: props.topColor }}
						onClick={props.selectShop}
						>
						{!props.selected ? "Seleziona" : "Deseleziona"}
					</p>
				</div>
			</div>
		</div>
	);
}

ShopPanel.defaultProps = {
	topColor: "#009bcc",
};

export function ShopSelector(props) {
	var el_list = props.list.map((v) => {
		const category = props.categoryFilter.categories.find(
			(category) => v.category === category.name
		);

		return (
			<ShopPanel
				key={v.id}
				{...v}
				selected={props.selected === v.id}
				//onClick={() => props.onClick(v.id)}
				selectShop={() => props.onClick(v.id)}
				topColor={category ? category.color : undefined}
			/>
		);
	});

	return (
		<div className="col-md-8">
			{props.isLoading && (
				<div className="text-center">
					<div className="spinner-grow text-primary" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}

			{!props.isLoading && el_list.length > 0 && (
				<section
					className={cn({
						selected: props.selected,
						checkout: props.appState === CHECKOUT_STATE,
					})}
					id="shop-selector">
					{el_list}
				</section>
			)}

			{!props.isLoading && el_list.length === 0 && (
				<div className="empty-category">
					<h5>Nessun negozio trovato</h5>
				</div>
			)}
		</div>
	);
}
