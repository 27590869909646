import React, { Component } from "react";
import style from "./Menu.module.scss";
import NavBar from "../Components/NavBar";
import cn from "classnames";
import CategoryButton from "../Components/CategoryButton";
import { getMenu } from "../Services/Api";
import LoadSpinner from "../Components/LoadSpinner";
import { alertInitState } from "../Components/Alert";

const FILTER_ALL = "ALL";

export default class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: FILTER_ALL,
			data: {
				type: null,
				name: null,
				description: null,
				openings: null,
				img: null,
				courses: [],
			},
			isLoading: true,
			alert: { ...alertInitState },
			searchFilter: "",
		};
	}

	componentDidMount() {
		//fetch data
		this.updateMenu();
	}

	componentDidUpdate(nextProps) {
		if (
			nextProps.match.params.resturant !==
			this.props.match.params.resturant
		) {
			this.updateMenu();
		}
	}

	updateMenu = async () => {
		const resturant = this.props.match.params.resturant;
		this.setState({ isLoading: true });

		try {
			const menu = await getMenu(resturant);
			this.setState({
				data: menu,
				isLoading: false,
			});
		} catch (e) {
			this.setState({
				isLoading: false,
				alert: { visible: true, ...e },
			});
		}
	};

	selectCategory = (value) => {
		this.setState({ filter: value });
	};

	closeAlert = () => {
		this.setState({ alert: alertInitState });
	};

	categoriesButtons = () => {
		const courses = this.state.data.courses;
		return courses.map((course) => {
			return (
				<CategoryButton
					key={course.name}
					categoryName={course.name}
					value={course.name}
					filter={this.state.filter}
					selectCategory={this.selectCategory}
				/>
			);
		});
	};

	filteredSections = () => {
		const courses = this.state.data.courses;
		let sections = courses.filter((course) => {
			return (
				this.state.filter === FILTER_ALL ||
				this.state.filter === course.name
			);
		});

		return sections.map((course) => (
			<Section key={course.name} course={course} />
		));
	};

	onSearch = (event) => {
		const value = event.currentTarget.value;
		this.setState({ searchFilter: value });
	};

	searchedDishes = () => {
		const pattern = this.state.searchFilter.toLowerCase();
		const courses = this.state.data.courses;
		let results = [];

		if (this.state.searchFilter !== "") {
			results = courses.reduce((courses, course) => {
				const sub_categories = course.sub_categories.reduce(
					(subCats, subCat) => {
						const dishes = subCat.dishes.filter((dish) => {
							return (
								(dish.name &&
									dish.name
										.toLowerCase()
										.includes(pattern)) ||
								(dish.ingredients &&
									dish.ingredients
										.toLowerCase()
										.includes(pattern)) ||
								(dish.description &&
									dish.description
										.toLowerCase()
										.includes(pattern))
							);
						});

						if (dishes.length > 0) {
							return subCats.concat({
								...subCat,
								dishes: dishes,
							});
						} else {
							return subCats;
						}
					},
					[]
				);

				if (sub_categories.length > 0) {
					return courses.concat({
						...course,
						sub_categories: sub_categories,
					});
				} else {
					return courses;
				}
			}, []);
		}

		if (results.length > 0)
			return results.map((course) => (
				<Section key={course.name} course={course} />
			));
		else
			return (
				<p>
					<i className="icofont-search-1"></i>&nbsp;Nessun risultato trovato
				</p>
			);
	};

	render() {
		const resturant = this.state.data;
		const resturantImg = resturant.img;

		return (
			<>
				<NavBar />

				<LoadSpinner
					isLoading={this.state.isLoading}
					alert={this.state.alert}
					className="clusu-content"
					loadingMessage="Caricamento..."
					onCloseAlert={this.closeAlert}>
					{resturantImg && (
						<div
							className={style.resturant_image}
							style={{
								backgroundImage: "url(" + resturantImg + ")",
							}}
						/>
					)}

					<div className="container">
						<div className="row">
							<div className={cn("col-12", style.menu_header)}>
								<p className={style.type}>
									- {resturant.type} -
								</p>
								<h1>{resturant.name}</h1>
								<p className={style.description}>
									{resturant.description}
								</p>
								{resturant.openings && (
									<p className={style.openings}>
										<b>Siamo Aperti: </b>
										{resturant.openings}
									</p>
								)}
							</div>
						</div>

						<div className="row">
							<div className={cn("col-12", style.search_field)}>
								<div className="input-group clusu-rounded">
									<input
										type="search"
										className="form-control"
										placeholder="Cerca nel menù"
										value={this.state.searchFilter}
										onChange={this.onSearch}
									/>
									<div className="input-group-append">
										<span className="input-group-text">
											<i className="icofont-search-1"></i>
										</span>
									</div>
								</div>
							</div>
						</div>

						{this.state.searchFilter === "" && (
							<section>
								<div className="row">
									<div
										className={cn(
											"category-selector",
											style.category_selector
										)}>
										<CategoryButton
											categoryName="Tutti i prodotti"
											filter={this.state.filter}
											value={FILTER_ALL}
											selectCategory={this.selectCategory}
										/>
										{this.categoriesButtons()}
									</div>
								</div>

								{this.filteredSections()}
							</section>
						)}

						{this.state.searchFilter !== "" && (
							<div className={style.search_content}>
								{this.searchedDishes()}
							</div>
						)}
					</div>
				</LoadSpinner>
			</>
		);
	}
}

const Section = (props) => {
	const { name, sub_categories } = props.course;

	const items = sub_categories.map((subCategory, i) => {
		return <SubCategory key={i} subCategory={subCategory} />;
	});

	return (
		<section className={style.course}>
			<h4>{name}</h4>
			{items}
		</section>
	);
};

const SubCategory = (props) => {
	const { name, dishes } = props.subCategory;

	const items = dishes.map((dish, i) => {
		return <Dish key={i} {...dish} />;
	});

	return (
		<>
			{name && <h5 className={style.sub_category}>{name}</h5>}
			{items}
		</>
	);
};

const Dish = ({ name, description, price, ingredients }) => {
	return (
		<div className={style.dish}>
			<p className={style.dish_title}>
				<span>{name}</span>&nbsp;
				<span style={{ color: "#009BCC" }}>{price}</span>
			</p>

			{description && (
				<p className={style.dish_description}>{description}</p>
			)}

			{ingredients && (
				<p className={style.dish_ingredients}>
					<b>Ingredienti: </b>
					{ingredients}
				</p>
			)}
		</div>
	);
};
