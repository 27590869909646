import React from "react";
//import PropTypes from "prop-types";
import {
	PURCHASE_COMPLETE_STATE,
	PURCHASE_ERROR_STATE,
	CHECKOUT_FORM_SUBMITTING_STATE,
} from "../Services/AppStates";
import { createOrder as postOrder } from "../Services/Api";
import { createOrder } from "./PayPalButton";

const NoAmountOrderButton = (props) => {
	const submitPurchase = async (e) => {
		e.preventDefault();
		const order = createOrder(props.data);

		props.setAppState(CHECKOUT_FORM_SUBMITTING_STATE);

		try {
			let orderID = await postOrder(order).then(response=>response.orderId);
			//this.setState({ isSubmitting: false });
			props.setOrderId(orderID);
			props.setAppState(PURCHASE_COMPLETE_STATE);
		} catch (e) {
			props.setAppState(PURCHASE_ERROR_STATE);
		}
	};

	const isSubmitting = props.appState === CHECKOUT_FORM_SUBMITTING_STATE;
	return (
		<div className="form-group">
			<button
				className="form-control btn btn-primary"
				onClick={submitPurchase}
				disabled={isSubmitting}>
				{!isSubmitting && "Effettua l'ordine"}
				{isSubmitting && (
					<div className="spinner-border text-light spinner-border-sm" />
				)}
			</button>
		</div>
	);
};

NoAmountOrderButton.propTypes = {};

export default NoAmountOrderButton;
