import React from "react";
import Navbar from "../Components/NavBar";
import {trackSocialClick} from '../AppInsight';

const CreditsSection = (props) => {
	return (
		<section className="credits-section">
			<h6>{props.sectionName}</h6>

			<div className="credits-section-container">
				{props.children}
			</div>
		</section>
	);
};

const Person = ({ name, surname, role, social }) => {

    const displayName = name +" "+surname;

    const onSocialClick = (social)=>{
        trackSocialClick(displayName,social);
    }

	return (
		<div className="person">
			<div className="person-img">
				<img
					src={process.env.PUBLIC_URL + "/team/" + name.toLowerCase() +"_"+surname.toLowerCase() + ".jpg"}
					alt={displayName}
				/>
			</div>
			<div className="person-info">
				<p className="person-name">{displayName}</p>
				<p className="person-role">{role}</p>
			</div>
			<div className="person-social">
				{social.linkedin && (
					<a href={social.linkedin} onClick={()=>{onSocialClick('LINKEDIN')}}>
						<i className="icofont-linkedin"></i>
					</a>
				)}
				{social.github && (
					<a href={social.github} onClick={()=>{onSocialClick('GITHUB')}}>
						<i className="icon-github-circled"></i>
					</a>
				)}
				{social.facebook && (
					<a href={social.facebook} onClick={()=>{onSocialClick('FACEBOOK')}}>
						<i className="icofont-facebook"></i>
					</a>
				)}
				{social.instagram && (
					<a href={social.instagram} onClick={()=>{onSocialClick('INSTAGRAM')}}>
						<i class="icofont-instagram"></i>
					</a>
				)}
				{social.twitter && (
					<a href={social.twitter} onClick={()=>{onSocialClick('TWITTER')}}>
						<i class="icofont-twitter"></i>
					</a>
				)}
				{social.web && (
					<a href={social.web} onClick={()=>{onSocialClick('WEB')}}>
						<i class="icofont-web"></i>
					</a>
				)}
				{social.mail && (
					<a href={"mailto:" + social.mail} onClick={()=>{onSocialClick('MAIL')}}>
						<i className="icofont-email"></i>
					</a>
				)}
			</div>
		</div>
	);
};

export default function Credits() {
	const team = {
		Organizzazione:[{
            name: "Giovanni",
            surname: "Baretti",
            role:"Public Relations",
            social: {
				linkedin: 'http://linkedin.com/in/giovanni-baretti-708760176',
				facebook: 'http://facebook.com/giovanni.baretti'
            }
		},{
            name: "Marco",
            surname: "Brasi",
            role:"Public Relations",
            social: {
				linkedin: 'http://linkedin.com/in/marco-brasi-b8a5ab84',
				facebook: 'https://www.facebook.com/Brasi92'
            }
		}],
		Sviluppo: [{
            name: "Lorenzo",
            surname: "Giudici",
            role:"Software Engineer",
            social: {
				github: 'https://github.com/lorenzogiudici5',
				linkedin: 'https://www.linkedin.com/in/lorenzogiudici5/'
            }
		},{
            name: "Simone",
            surname : "Micheli",
            role:"Software Engineer",
            social: {
				github: 'https://github.com/SimoneMicheli',
				linkedin: 'https://www.linkedin.com/in/simone-micheli/'
            }
        },{
			name: "Anna",
			surname: "Scandella",
			role:"Designer",
			social: {
				linkedin: 'https://www.linkedin.com/in/anna-scandella-175306107/',
				web:'https://www.annascandella.com/'
			}
		}]
    };
    
    const sections = Object.entries(team).map(([sectionName,members],sectionIndex)=>{

        const people = members.map((member,i)=>{
            return (<Person key={i} {...member}/>)
        })

        return (<CreditsSection key={sectionIndex} sectionName={sectionName}>
            {people}
        </CreditsSection>)
    })

	return (
		<>
			<Navbar />

			<div className="container">
				<div className="content">
					<div className="credits-header">
						<h1>Chi Siamo</h1>
					</div>

                    {sections}
					
				</div>
			</div>
		</>
	);
}
