import React from "react";
import NavBar from '../Components/NavBar';
import LogoBig from '../img/logo_big.svg';

export default function PageNotFound() {
	return (
		<>
		<NavBar />
		<div id="page-error">
			<div className="bg"></div>
			<img src={LogoBig} alt="logo big"/>
			<div className="text">
				<p>Oh No! Qualcosa è andato storto</p>
				<p>404 Not Found</p>
				</div>
		</div>
		</>
	);
}