import React from "react";
import Navbar from "../Components/NavBar";

export default function ShoppingVouchersRequest() {
	return (
		<>
			<Navbar />
			<div
				dangerouslySetInnerHTML={{
					__html:
						'<iframe id="typeform-full" width="100%" height="800px frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/e4eVlRoi?typeform-medium=embed-snippet"></iframe> <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script>',
				}}
			/>
		</>
	);
}
