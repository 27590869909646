import React from "react";
import LogoBig from '../img/logo_big.svg';
import { trackFatalError } from '../AppInsight';

export default class ErrorBoundary extends React.Component{

	constructor(props){
		super(props);
		this.props = props;
		this.state = {hasError:false}
	}

	componentDidCatch(error,info){
		trackFatalError(error,info)
		this.setState({hasError: true})
	}

	render(){
		if (this.state.hasError)
			return (<div id="page-error">
			<div className="bg"></div>
			<img src={LogoBig} alt="logo big"/>
			<div className="text">
				<p>Oh No! Qualcosa è andato storto</p>
				<p>Ci scusiamo, riprova più tardi</p>
				</div>
		</div>)
		else
			return (this.props.children)
	}
}