export enum Extras {
	EXTRA_DISCOUNT_10 = "EXTRA_DISCOUNT_10",
	DELIVERY = "DELIVERY",
	CHARITY = "CHARITY",
}
export interface Shop {
	name: string;
	id: string;
	description?: string;
	//ready: boolean;
	image?: string;
	//locations: Array<ShopLocation>,
	category: string;
	coupons?: Array<Coupon>;
	iban?: string;
	payPal?: string;
	website?: string;
	facebook?: string;
	instagram?: string;
	email?: string;
	address?: string;
	//delivery?: boolean;
	extras?: Array<Extras>;
}

export interface ShopLocation {
	address: string;
	//img?: string,
	latLong?: { lat: string; long: string };
	openingHours?: string;
	phoneNumber?: string;
}

export interface Category {
	id: string;
	display: string;
}

export interface Coupon {
	amount: number;
	isFree: boolean;
	id: string;
	code: string;
	releaseDate: string;
	useDate?: string;
}
