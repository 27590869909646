import React from "react";
import style from "./Comments.module.scss";
import Navbar from "../Components/NavBar";
import cn from "classnames";
import { getComments, postComment } from "../Services/Api";
import { Formik, Field } from "formik";
import CustomInputFieldError from "../Components/CustomInputFieldError";
import * as yup from "yup";
import Alert, {alertInitState} from "../Components/Alert";
import {parse as parseDate, format as formatDate} from 'date-fns';
import {it as dateITLocale} from 'date-fns/locale'

const Paginator = ({ pageInfo, onPageSelection }) => {
	const pageLinks = [];
	const startIndex = pageInfo.page - 2 > 0 ? pageInfo.page - 2 : 0;
	const endIndex =
		pageInfo.page + 2 <= pageInfo.lastPage
			? pageInfo.page + 2
			: pageInfo.lastPage;

	for (let i = startIndex; i <= endIndex; i++) {
		pageLinks.push(
			<li
				key={i}
				className={cn("page-item", { active: i === pageInfo.page })}>
				<button
					className="page-link"
					onClick={(e) => {
						e.preventDefault();
						onPageSelection(i);
					}}>
					{i + 1}
				</button>
			</li>
		);
	}

	const prevPage = pageInfo.page - 1 >=0 ? pageInfo.page -1 : 0
	const nextPage = pageInfo.page + 1 <= pageInfo.lastPage ? pageInfo.page + 1 : pageInfo.lastPage

	return (
		<nav className={cn({'hidden':pageInfo.lastPage===0})}>
			<ul className="pagination justify-content-center justify-content-lg-end">
				<li
					className={cn("page-item", {
						disabled: pageInfo.page <= 0,
					})}>
					<button
						className="page-link"
						onClick={(e) => {
							e.preventDefault();
							onPageSelection(0);
						}}>
						<span aria-hidden="true">&laquo;</span>
					</button>
				</li>
				<li
					className={cn("page-item", {
						disabled: pageInfo.page <= 0,
					})}>
					<button
						className="page-link"
						onClick={(e) => {
							e.preventDefault();
							onPageSelection(prevPage);
						}}>
						<span aria-hidden="true">&lsaquo;</span>
					</button>
				</li>
				{pageLinks}
				<li
					className={cn("page-item", {
						disabled: pageInfo.page === pageInfo.lastPage,
					})}>
					<button
						className="page-link"
						onClick={(e) => {
							e.preventDefault();
							onPageSelection(nextPage);
						}}>
						<span aria-hidden="true">&rsaquo;</span>
					</button>
				</li>
				<li
					className={cn("page-item", {
						disabled: pageInfo.page === pageInfo.lastPage,
					})}>
					<button
						className="page-link"
						onClick={(e) => {
							e.preventDefault();
							onPageSelection(pageInfo.lastPage);
						}}>
						<span aria-hidden="true">&raquo;</span>
					</button>
				</li>
			</ul>
		</nav>
	);
};

const parseDateFormat = "dd/MM/yyyy HH:mm:ss"
const formatDateFormat = "H:mm eee d MMM yyyy"
const formatDateOpt = {locale:dateITLocale}

const Comment = ({ title, name, message, date }) => {
	const commentDate = parseDate(date,parseDateFormat,new Date())
	return (
		<div className={style.comment}>
			<h3 className={style.sectionHeader}>{title}</h3>
			{name && <small className="text-muted">di {name} - </small>}
			<small className="text-muted">{formatDate(commentDate,formatDateFormat,formatDateOpt)}</small>
			<p>{message}</p>
		</div>
	);
};

const CommentForm = (props) => {
	const initialValues = {
		name: "",
		surname: "",
		email: "",
		isAnonymous: false,
		title: "",
		message: "",
	};

	const validationSchema = yup.object().shape({
		email: yup.string().email("Email non valida"),
		title: yup.string().required("Richiesto"),
		message: yup.string().required("Richiesto"),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnMount={true}
			onSubmit={props.postComment}>
			{({ handleSubmit, handleReset, isSubmitting }) => (
				<form
					className={cn(style.commentForm, {
						[style.visible]: props.visible,
					})}
					onSubmit={handleSubmit}
					onReset={handleReset}>
					<fieldset disabled={isSubmitting}>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label>Nome</label>
								<CustomInputFieldError
									name="name"
									className="form-control"
									placeholder="Nome"
								/>
							</div>
							<div className="form-group col-md-6">
								<label>Cognome</label>
								<CustomInputFieldError
									name="surname"
									className="form-control"
									placeholder="Cognome"
								/>
							</div>
						</div>

						<div className="form-group">
							<label>Email</label>
							<CustomInputFieldError
								type="email"
								name="email"
								className="form-control"
								placeholder="Email"
							/>
						</div>
						<div className="form-group">
							<div className="form-check">
								<Field
									className="form-check-input"
									type="checkbox"
									name="isAnonymous"
								/>
								<label className="form-check-label">
									Anonimo{" "}
									<small className="text-muted">
										Attivando questa opzione, il tuo nome
										non comparirà nella lista dei commenti
									</small>
								</label>
							</div>
						</div>

						<div className="form-group">
							<label>
								Titolo
								<span className="required-field">*</span>
							</label>
							<CustomInputFieldError
								type="text"
								name="title"
								className="form-control"
								placeholder="titolo"
							/>
						</div>

						<div className="form-group">
							<label>
								Commento
								<span className="required-field">*</span>
							</label>
							<CustomInputFieldError
								inputType="textarea"
								className="form-control"
								name="message"
								placeholder="commento"
							/>
						</div>

						<div className="form-group">
							<Field>
								{({ field, form }) => (
									<button
										disabled={!form.isValid}
										className="form-control btn btn-primary"
										type="submit">
										Invia
									</button>
								)}
							</Field>
						</div>
					</fieldset>
				</form>
			)}
		</Formik>
	);
};

class CommentsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			comments: [],
			isLoading: false,
			pageInfo: { page: 0, lastPage: 0 },
			formVisible: false,
			alert: { ...alertInitState },
		};
	}

	async loadComments(page = 0) {
		try {
			this.setState({ isLoading: true });
			const {messages,pageInfo} = await getComments(page);
			this.setState({
				comments: messages,
				pageInfo: pageInfo,
				isLoading: false,
			});
		} catch (e) {
			this.setState({
				alert: { visible: true, ...e },
			});
		}
	}

	toggleForm = (e) => {
		e.preventDefault();
		this.setState((oldState) => {
			return { formVisible: !oldState.formVisible };
		});
	};

	postComment = async (values, formikBag) => {
		this.setState(
			{alert: {visible:true,status:'INFO',msg:'Invio commento in corso...'}}
		)
		return postComment(values)
			.then(() => {
				formikBag.resetForm();
				this.setState({
					formVisible: false,
					alert: {
						visible: true,
						status: "OK",
						msg: "Commento inviato correttamente",
					},
				});
				//reload current page
				this.loadComments(this.state.pageInfo.page);
			})
			.catch((e) => {
				this.setState({
					alert: { visible: true, ...e },
				});
			});
	};

	loadPage = async (page) => {
		this.setState({ alert: alertInitState, formVisible:false });
		this.loadComments(page);
	};

	closeAlert = () => {
		this.setState({ alert: alertInitState });
	};

	componentDidMount() {
		this.loadComments();
	}

	render() {
		const commentsList = this.state.comments.map((c, i) => (
			<Comment key={i} {...c} />
		));

		return (
			<>
				<Navbar />
				<div className="container">
					<div className="row justify-content-center">
						<h1 className={style.page_header}>Dicono di noi</h1>
					</div>

					{this.state.alert.visible && (
						<div className="row justify-content-center">
							<div className="col-md-8">
								<Alert alert={this.state.alert} onCloseAlert={this.closeAlert}/>
							</div>
						</div>
					)}

					{this.state.isLoading && (
						<div className="text-center">
							<div
								className="spinner-grow text-primary"
								role="status">
								<span className="sr-only">Caricamento...</span>
							</div>
						</div>
					)}

					{!this.state.isLoading && (
						<div className="row justify-content-center">
							<section className="col-md-8">
								<div className={style.commands}>
										<button
											className="btn btn-secondary"
											onClick={this.toggleForm}
											disabled={this.state.pageInfo.page !== 0}>
											Lascia un commento&nbsp;
											<i
												className={cn(
													"icofont-plus",
													style.addIcon,
													{
														[style.open]: this.state
															.formVisible,
													}
												)}></i>
										</button>
									

									<Paginator
										pageInfo={this.state.pageInfo}
										onPageSelection={this.loadPage}
									/>
								</div>

								{this.state.pageInfo.page === 0 && (
									<CommentForm
										visible={this.state.formVisible}
										postComment={this.postComment}
									/>
								)}
							</section>
						</div>
					)}

					{!this.state.isLoading && (
						<div className="row justify-content-center">
							<section className={cn("col-md-8", style.comments)}>
								{commentsList.length === 0 && <p style={{textAlign:'center'}}>Ancora nessun commento</p>}
								{commentsList}
							</section>
						</div>
					)}
				</div>
			</>
		);
	}
}

export default CommentsPage;
