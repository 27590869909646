import React from "react";
import logo from "../img/logo.svg";
import {Link} from 'react-router-dom';

export default function Navbar(){
    return (<nav className="navbar main-navbar">
    <div className="container">
        <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" />
        </Link>
    </div>
</nav>)
}