import React, { createRef } from "react";
import * as AppStates from "../Services/AppStates";
import { createOrder as createOrderApi, sendPurchaseComplete } from "../Services/Api";
import {CHECKOUT_FORM_SUBMITTING_STATE} from '../Services/AppStates';
import {
	trackStartCreateOrder,
	trackCreatedOrder,
	trackPayPalButtonError,
	trackOrderError,
	trackOrderApproved,
	trackOrderComplete,
} from "../AppInsight";

export function createOrder(formData) {
	return {
		name: formData.name,
		surname: formData.surname,
		shopId: formData.shopId,
		amount: formData.amount.payment,
		mail: formData.mail,
		amountIndex: formData.amountIndex,
		promoCode: formData.useDiscount ? formData.discountCode : '',
	};
}

class PayPalButton extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.el = createRef();
	}

	componentDidMount() {
		const paypalConfig = {
			style: {
				shape: "rect",
				color: "blue",
				layout: "vertical",
				label: "pay",
			},
			createOrder: this.createOrder,
			onApprove: this.onApprove,
			onError: this.onError,
		};

		this.button = window.paypal.Buttons(paypalConfig);
		this.button.render(this.el.current);
	}

	componentWillUnmount() {
		if (this.button) this.button.close();
	}

	createOrder = async (data, actions) => {
		const order = createOrder(this.props.data)

		trackStartCreateOrder({
			amount: order.amount,
			shopId: order.shopId,
		});
		console.info("Creating order", order);

		//send form data to server
		return createOrderApi(order)
			.then((json) => {
				const orderId = json.orderId
				this.props.setOrderId(orderId);
				console.info("Order id: ", orderId);
				trackCreatedOrder({
					orderId: orderId,
				});
				return json.paypalId; //return paypalId to paypal button
			})
			.catch((reason) => {
				if (reason.status && reason.status === "PREVIEW")
					this.props.setAppState(AppStates.PREVIEW_STATE);
				else this.props.setAppState(AppStates.PURCHASE_ERROR_STATE);
			});
	};

	onApprove = async (data, actions) => {
		// This function captures the funds from the transaction.

		//this.props.setAppState(AppStates.PURCHASE_COMPLETE_STATE);
		//do not change stauts here, if done, after captue action an error occure
		//because the button component is already removed and can't be updated by paypal sdk.

		this.props.setAppState(CHECKOUT_FORM_SUBMITTING_STATE);

		trackOrderApproved({
			orderId: data.orderID,
			paymentId: data.paymentID,
		});

		var localProps = this.props;
		return actions.order
			.capture()
			.then(function (details) {
				if (details.status === "COMPLETED") {
					localProps.setAppState(AppStates.PURCHASE_COMPLETE_STATE);
					sendPurchaseComplete(localProps.orderId, data.orderID).catch(error=>console.error(error))
					trackOrderComplete({
						orderId: data.orderID, //paypal order id
						paymentId: data.paymentID,
					});
				} else {
					localProps.setAppState(AppStates.PURCHASE_ERROR_STATE);
					trackOrderError({
						message: "Error on approve",
						details: details.status,
						orderId: data.orderID,
						paymentId: data.paymentID,
					});
				}
			})
			.catch((error) => {
				trackOrderError({
					message: error.message,
				});
				this.onError(error);
			});
	};

	onError = (error) => {
		trackPayPalButtonError({
			message: "Error on paypal button",
			details: error.message,
		});
		console.log("button error", error);
		this.props.setAppState(AppStates.PURCHASE_ERROR_STATE);
	};

	render() {
		return <div id="paypal-button" ref={this.el} />;
	}
}

export default PayPalButton;
