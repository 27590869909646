import React from "react";
import PropTypes from "prop-types";
import {Field} from 'formik';
import cn from 'classnames';

function SubmitButton(props) {

    const className = cn("form-control",props.className ? props.className : "btn btn-primary")

	return (
		<div className="form-group">
			<Field>
				{({ field, form }) => (
					<button
						disabled={!form.isValid}
						className={className}
						type="submit">
						{props.children}
					</button>
				)}
			</Field>
		</div>
	);
}

SubmitButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default SubmitButton;
