import React from "react";
import PropTypes from "prop-types";
import Alert, { alertObjectShape } from "./Alert";
import cn from "classnames";

function LoadSpinner({ className = "", loadingMessage = null, ...props }) {
	if (props.isLoading)
		return (
			<div className={cn("text-center", className)}>
				<div className="spinner-grow text-primary" role="status">
					<span className="sr-only">{loadingMessage}</span>
				</div>
				<p>{loadingMessage}</p>
			</div>
		);
	else if (props.alert.visible)
		return (
			<div className={cn("row justify-content-center", className)}>
				<div className="col-md-8">
					<Alert
						alert={props.alert}
						onCloseAlert={props.onCloseAlert}
					/>
				</div>
			</div>
		);
	else return props.children;
}

LoadSpinner.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	children: PropTypes.node,
	onCloseAlert: PropTypes.func,
	alert: PropTypes.shape(alertObjectShape),
	className: PropTypes.string,
	loadingMessage: PropTypes.string,
};

LoadSpinner.defaultProps = {
	alert: {visible:false,msg:"",status:"OK"},
	onCloseAlert: ()=>{}
}

export default LoadSpinner;
