import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { browserHistory } from "./AppInsight";

import "./App.scss";
import ErrorBoundary from "./Components/ErrorBoundary";
import MainPurchase from "./Pages/MainPurchase";
import RedeemPage from "./Pages/RedeemPage";
import Footer from "./Components/Footer";
import PageNotFound from "./Pages/PageNotFound";
import Legal from "./Pages/Legal";
import Credits from "./Pages/Credits";
import CommentsPage from "./Pages/CommentsPage";
import Menu from "./Pages/Menu";
import MenuUpload from "./Pages/MenuUpload";
import Ripartiamo from "./Pages/Ripartiamo";
import Join from "./Pages/Join";
import ShoppingVouchers from "./Pages/ShoppingVouchers";
import ShoppingVouchersRequest from "./Pages/ShoppingVouchersRequest";
import ShoppingVouchersShop from "./Pages/ShoppingVouchersShop";
import ShoppingVouchersShopRequest from "./Pages/ShoppingVouchersShopRequest";

function App() {
	return (
		<ErrorBoundary>
			<Router history={browserHistory}>
				<div id="App">
					<section id="main-content">
						<Switch>
							<Route path="/" component={MainPurchase} exact />
							<Route path="/redeem" component={RedeemPage} />
							<Route path="/legal" component={Legal} />
							<Route path="/credits" component={Credits} />
							<Route path="/comments" component={CommentsPage} />
							<Route path="/menu/upload" component={MenuUpload} />
							<Route path="/menu/:resturant" component={Menu} />
							<Route
								path="/321ripartiamo"
								component={Ripartiamo}
							/>
							<Route path="/join" component={Join} />
							<Route
								path="/buoni-spesa"
								exact
								component={ShoppingVouchers}
							/>
							<Route
								path="/buoni-spesa/richiesta"
								component={ShoppingVouchersRequest}
							/>
							<Route
								path="/buoni-spesa/esercente"
								exact
								component={ShoppingVouchersShop}
							/>
							<Route
								path="/buoni-spesa/esercente/richiesta"
								component={ShoppingVouchersShopRequest}
							/>
							<Route path="*">
								<PageNotFound />
							</Route>
						</Switch>
					</section>

					<Footer />
				</div>
			</Router>
		</ErrorBoundary>
	);
}

export default App;
