import React, { useEffect, useCallback, useState, useRef } from "react";
import propTypes from 'prop-types'
import { easeOutExpo } from "js-easing-functions";


const ClusuNumbers = ({ value, children, duration = 2000, uom }) => {
	const [state, setState] = useState({
		currentValue: 0,
		animated: false,
	});
	const el = useRef();

	/**
	 * Make the number animation
	 * Function is registered as callback
	 */
	const animate = useCallback(
		(entries, observer) => {
			//target is visible
			const visible = entries[0].isIntersecting;

			//target is visible and animation was never executed
			if (!state.animated && visible) {
				const startTime = new Date().getTime();

				//tick of amination
				const tick = () => {
					const elapsed = new Date().getTime() - startTime;
					const currentValue = easeOutExpo(
						elapsed,
						0,
						value,
						duration
					);

                    //animation is runnint
					if (elapsed < duration) {
						setState({
							currentValue: Math.ceil(currentValue),
							animated: false,
						});
						//new animation frame
						requestAnimationFrame(tick);
					} else {
                        //animation is terminated
						//set the last value and set animation terminated
						setState({
							currentValue: value,
							animated: true,
						});
					}
				};

				//start the animation
				tick();
			}
		},
		[value, state.animated, duration]
	);

	/**
	 * Register the observer when the animation function change or element change
	 */
	useEffect(() => {
		const options = {
			threshold: 0.5,
		};

		//register new observer
		const observer = new IntersectionObserver(animate, options);
		observer.observe(el.current);

		return () => {
			//remove the observer on the old elment
			observer.disconnect();
		};
	}, [el, animate]);

	return (
		<div className="col-md-4 clusu-numbers" ref={el}>
			<p className="value">{state.currentValue}{uom ? " "+uom:""}</p>
			<p className="label">{children}</p>
		</div>
	);
};

ClusuNumbers.propType = {
    value: propTypes.number.isRequired,
    uom: propTypes.string,
    children: propTypes.node
}

export default ClusuNumbers;