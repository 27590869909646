import React from "react";
import logo from "../img/logo.svg";
import { Link } from "react-router-dom";
import style from "./Join.module.scss";
import baloon from "../img/baloon.svg";
import join_example from "../img/join_example.svg";
import { useCallback } from "react";

const joinURL = "https://bit.ly/Iscrizione_Clusu";

export default () => {
	const scrollToContent = useCallback(() => {
		document
			.getElementById("content")
			.scrollIntoView({ behavior: "smooth" });
	}, []);

	return (
		<div>
			<header id="intro" className={style.join_intro}>
				<div className={style.baloon}>
					<div className={style.positioner}>
						<img src={baloon} alt="baloon" />
					</div>
				</div>

				<div className="container">
					<div id="logo">
						<Link to="/">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div id="intro-content">
						<p>
							Hai un'attività
							<br />
							commerciale o di servizi
							<br />
							a Clusone?
							<br />
							<b>Sali a bordo!</b>
						</p>

						<div>
							<a
								className="btn clusu-btn white-button"
								href={joinURL}>
								ISCRIVITI SUBITO
							</a>
							<button
								className="btn dark-button"
								onClick={scrollToContent}>
								SCOPRI I VANTAGGI!
							</button>
						</div>
					</div>
				</div>

				<div id="mouse-scroll">
					<span>
						<i className="icofont-scroll-down"></i>
					</span>
				</div>
			</header>

			<section id="content">
				<div className="container">
					<div className="info-section section-aria">
						<div className="info-header">
							<span className="bottom-line">
								ISCRIVITI SUBITO, È GRATIS!
							</span>
						</div>
						<div className="info-content">
							<div className="row justify-content-center">
								<div className="col-md-7">
									<p className="text-center">
										Abbiamo realizzato una piattaforma che
										consente ai cittadini, potenziali
										clienti, di{" "}
										<b>supportare le attività locali</b> per
										far vivere sempre più il nostro
										territorio.
										<br /> Ogni cittadino ha così la
										possibilità di acquistare un buono (il
										CluSÚ) selezionando tra le attività
										commerciali che hanno aderito al nostro
										progetto.
									</p>

									<p className="text-center">
										L’iscrizione alla piattaforma è{" "}
										<b>gratuita</b> sia per il cittadino sia
										per le attività commerciali e i
										pagamenti sono gestiti in modo sicuro e
										tracciabile tramite il circuito PayPal
										(è possibile pagare anche con carta
										prepagata).
									</p>

									<div className="text-center">
										<a
											className="btn clusu-btn dark-button"
											href={joinURL}>
											ISCRIVITI SUBITO
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="dark-section">
				<div className="container">
					<div className="info-section section-aria">
						<div className="info-header">
							<span className="bottom-line">
								COME GESTISCO I CLUSÙ
							</span>
						</div>
						<div className="info-content">
							<div className="row">
								<div className="col-md-6 coupon-example">
									<img src={join_example} alt="iscrizione" />
								</div>

								<div className="col-md-6">
									<div>
										<p>
											Ad ogni buono dovrà corrispondere
											l’emissione di regolare scontrino
											fiscale dell’importo indicato alla
											voce “importo pagato” non
											comprensivo della maggiorazione da
											voi applicata.
										</p>

										<p>
											Poiché il cliente che acquista da
											voi il Clusù lo ha già pagato
											(paypal o carta pregata) e l’importo
											vi è già stato (o vi sarà)
											accreditato direttamente sul vostro
											conto secondo le modalità da voi
											indicate, se il cliente volesse
											acquistare un bene/servizio per un
											importo maggiore di quello riportato
											nel buono, è consigliabile di
											eseguire doppia transazione fiscale
											(soprattutto per i pagamenti
											elettronici):
										</p>
										<ul>
											<li>
												una riferita al buono (in cui
												dovrà comparire anche lo sconto)
											</li>
											<li>
												ed una riferita all’importo
												rimanente del prodotto
												acquistato
											</li>
										</ul>
									</div>
									<div>
										<a
											className="btn clusu-btn white-button"
											href={joinURL}>
											ISCRIVITI SUBITO
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="info-section section-aria">
						<div className="info-header">
							<span className="bottom-line">
								COME RICEVO I PAGAMENTI?
							</span>
						</div>
						<div className="info-content">
							<div className="row justify-content-center">
								<div className="col-md-7">
									<p className="text-center">
										Al momento dell’iscrizione, ti
										chiederemo qualche dettaglio sulla tua
										attività e potrai indicare il metodo di
										pagamento da te preferito (bonifico
										bancario o PayPal).{" "}
									</p>

									<p className="text-center">
										Se ci sono ancora dei dubbi o se le
										indicazione fornite non sono chiare,
										contattateci senza problemi, cercheremo
										di chiamarvi o passare da voi appena
										sarà possibile.
										<br />
										<a href="mailto:info@clusu.com">
											<b>info@clusu.com</b>
										</a>{" "}
										oppure{" "}
										<a href="mailto:supporto@clusu.com">
											<b>supporto@clusu.com</b>
										</a>
										.
									</p>

									<div className="text-center">
										<a
											className="btn clusu-btn dark-button"
											href={joinURL}>
											ISCRIVITI SUBITO
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
