import React from "react";

function Availability(props) {
	switch (props.state) {
		case "AVAIL":
			return "Disponibile";
		default:
			return "Utilizzato";
	}
}

export default function CouponCheck({ coupon, onRedeem, appState }) {
	return (
		<section id="coupon-check">
			<h5 className="bottom-line">
				Verifica i dati del coupon
			</h5>

			<dl className="row">
				<dt className="col-sm-3">Negozio</dt>
				<dd className="col-sm-9">{coupon.shop}</dd>

				<dt className="col-sm-3">Nome</dt>
				<dd className="col-sm-9">{coupon.buyer_name}</dd>

				<dt className="col-sm-3">Cognome</dt>
				<dd className="col-sm-9">{coupon.buyer_surname}</dd>

				<dt className="col-sm-3 text-truncate">Importo Pagato</dt>
				<dd className="col-sm-9">{coupon.payed}€</dd>

				<dt className="col-sm-3 text-truncate">Importo bonus</dt>
				<dd className="col-sm-9">{coupon.bonus}€</dd>

				<dt className="col-sm-3 text-truncate">ID ordine</dt>
				<dd className="col-sm-9">{coupon.orderId}</dd>

				<dt className="col-sm-3 text-truncate">codice coupon</dt>
				<dd className="col-sm-9">{coupon.coupon_code}</dd>

				<dt className="col-sm-3 text-truncate">Data acquisto</dt>
				<dd className="col-sm-9">{coupon.purchase_date}</dd>

				<dt className="col-sm-3 text-truncate">Data utilizzo</dt>
				<dd className="col-sm-9">{coupon.use_date ? coupon.use_date : '-'}</dd>

				<dt className="col-sm-3 text-truncate">Stato coupon</dt>
				<dd className="col-sm-9">
					<Availability state={coupon.state} />
				</dd>
			</dl>
		</section>
	);
}
