import React from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import CustomInputFieldError from "./CustomInputFieldError";
import {couponSecretCodeRegex, shopCodeRegex} from '../Services/Globals';

export default function RedeemForm(props) {
	const initValues = {
		shop_code: "",
		coupon_code: "",
		coupon_secret_code: "",
	};

	const validationSchema = yup.object().shape({
		shop_code: yup.string().matches(shopCodeRegex,'Codice negozio non valido').required("Richiesto"),
		coupon_secret_code: yup.string().matches(couponSecretCodeRegex,'Codice segreto coupon non valido').required("Richiesto"),
	});

	const initialValues = Object.assign(
		{},
		initValues,
		//queryParams,
		props.initialValues
	);

	return (
		<Formik
			initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
			onSubmit={props.onRedeem}>
			<Form>
				<fieldset
					disabled={!props.coupon || props.coupon.state !== "AVAIL"}>
					<div className="form-group">
						<label>
							Codice segreto attività
							<span className="required-field">*</span>
						</label>

						<CustomInputFieldError
							name="shop_code"
							className="form-control"
							placeholder="Codice segreto attività"
						/>
					</div>

					<div className="form-group">
						<label>
							Codice segreto coupon
							<span className="required-field">*</span>
						</label>
						<CustomInputFieldError
							name="coupon_secret_code"
							className="form-control"
							placeholder="Codice segreto coupon"
						/>
					</div>

					<input type="hidden" name="coupon_code" />

					<p className="text-muted">
						Attenzione: Dopo questa operazione, il coupon non potrà
						più essere utilizzato
					</p>

					<Field>
						{({ field, form }) => {
							return (
								<button
									{...field}
									type="submit"
									className="btn btn-danger"
									disabled={!form.isValid}>
									Usa il coupon
								</button>
							);
						}}
					</Field>
				</fieldset>
			</Form>
		</Formik>
	);
}
