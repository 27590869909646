import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../img/footer_logo.svg";

export default function Footer() {
	return (
		<footer id="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-4 footer-logo">
						<Link to="/">
							<img src={footerLogo} alt="footer logo" />
						</Link>
					</div>

					<div className="col-md-4">
						<div className="footer-link-list">
							<ul className="list-unstyled">
								<li>
									<Link to="/join">Vuoi Partecipare?</Link>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<Link to="/321ripartiamo">
										321 Ripartiamo!
									</Link>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<Link to="/buoni-spesa">Buoni spesa</Link>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<a href="https://dashboard.clusu.com">
										Dashboard
									</a>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<Link to="/redeem">Usa il coupon</Link>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<Link to="/comments">Dicono di noi</Link>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<Link to="/legal">Note Legali</Link>
								</li>
							</ul>
							<ul className="list-unstyled">
								<li>
									<Link to="/credits">Chi siamo</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 d-flex flex-column justify-content-end align-items-md-end">
						<div
							className="fb-like-button"
							dangerouslySetInnerHTML={{
								__html: '<div class="fb-like" data-href="https://www.facebook.com/clusu.bg/" data-width="" data-layout="button_count" data-action="like" data-size="small" data-share="false"></div>',
							}}
						/>

						<div className="social">
							<a href="https://bit.ly/clusufb">
								<i className="icofont-facebook"></i>
							</a>
							<a href="https://bit.ly/clusuinstagram">
								<i className="icofont-instagram"></i>
							</a>
							<a href="mailto:info@clusu.com" rel="nofollow">
								<i className="icofont-email"></i>
							</a>
						</div>
						<div className="footer-copyright">
							<span>&copy; Copyright 2020 - clusu.com</span>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<p className="text-right"><small>Version: {process.env.REACT_APP_VERSION}</small></p>
					</div>
				</div>
			</div>
		</footer>
	);
}
