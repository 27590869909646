import React,{useState, useEffect} from 'react'
import { validateDiscount } from "../Services/Api";
import { discountCodeRegex } from "../Services/Globals";
import ModalMessage from "./ModalMessage";
import cn from "classnames";

function validateDiscountCodeField(value, amountIndex, wasValidated, originalPayment, discountValue, asyncMessage) {
	if (value.length <= 0 || value === "") return "Campo richiesto";
	else if (!discountCodeRegex.test(value)) return "Codice non valido";
	else if (amountIndex === "") return "selezionare un importo";
	else if (!wasValidated) return "Validare codice";
	else if (originalPayment>discountValue) return "L'importo selezionato è maggiore del valore del buono"
	else return asyncMessage;
}

const DiscountCoupon = (props) => {
	const {
		field,
		form,
		meta,
		setCouponCodeError,
		couponCodeError,
		originalPayment,
		shopCode,
	} = props;

	//use discount code
	const useDiscount = true;
	//coupon code validation in progress
	const [isValidating, setIsValidating] = useState(false);
	//coupon code has been validate vith the server (successfully or not)
	const [wasValidated, setWasValidated] = useState(false);
	//validation error from the server
	const [asyncValidationMessage, setAsycValidationMessage] = useState(
		undefined
	);

	const [modalState, setModalState] = useState({
		visible: false,
		title: "",
		html: "",
	});

		/**
	 * Update error message every time something change
	 */
	useEffect(() => {
		//check the status message to be shown
		if (isValidating) {
			setCouponCodeError("Validazione in corso")
			
		} else  {
			const error = validateDiscountCodeField(
				field.value,
				form.values.amountIndex,
				wasValidated,
				originalPayment,
				form.values.discountValue,
				asyncValidationMessage
			);
			setCouponCodeError(error)			
		} 
	}, [
		form.setFieldError,
		field.name,
		field.value,
		isValidating,
		wasValidated,
		asyncValidationMessage,
		form.values.discountValue,
		form.values.amountIndex,
		originalPayment
	]); //perform this function if value change or usediscount change

	/**
	 * Invalidate the lateset validation when selected shop change
	 */
	useEffect(() => {
		setWasValidated(false);
	}, [shopCode, useDiscount]);

	const onFieldCodeChange = (e) => {
		const value = e.currentTarget.value;
		setWasValidated((prevWasValidated) => {
			return field.value === value ? prevWasValidated : false;
		});
		form.setFieldValue("discountValue",undefined,false)
		form.setFieldValue(field.name, value, false); 
	};

	const showModal = (title, html) => {
		setModalState({
			visible: true,
			title: title,
			html: html,
		});
	};

	const hideModal = () => {
		setModalState({
			visible: false,
		});
	};

	/**
	 * Validate code by sending it to the server
	 * @param {event} e
	 */
	const validateCode = async (e) => {
		e.preventDefault();
		const discountCode = field.value;
		const amountIndex = form.values.amountIndex;

		setIsValidating(true);
		setWasValidated(true);

		try {
			const resp = await validateDiscount(
				discountCode,
				shopCode,
				amountIndex
			);

			if (resp.status === "OK") {
				form.setFieldValue("discountValue",resp.discountValue);
				setAsycValidationMessage(undefined);
			} else setAsycValidationMessage(resp.msg);
		} catch (e) {
			if (e.status === "WARNING") {
				showModal("Attenzione", e.html);
				setAsycValidationMessage(e.msg);
			} else setAsycValidationMessage(e.msg);
		} finally {
			setIsValidating(false);
		}
	};

	return (
		<div className="discount-coupon">
			<ModalMessage
				visible={modalState.visible}
				title={modalState.title}
				close={hideModal}>
				<div dangerouslySetInnerHTML={{ __html: modalState.html }} />
			</ModalMessage>

			<fieldset disabled={isValidating}>
				<div className="form-group">
					<div className="input-group">
						<input
							type="text"
							placeholder="Codice Promozionale"
							name={field.name}
							value={field.value}
							onChange={onFieldCodeChange}
							onBlur={field.onBlur}
							className={cn("form-control", {
								"is-valid":
									form.values.useDiscount && meta.touched && !couponCodeError,
								"is-invalid": meta.touched && couponCodeError,
							})}
						/>

						<div className="input-group-append">
							{!isValidating && couponCodeError === "Validare codice" && (
								<button
									className="btn btn-outline-secondary"
									onClick={validateCode}>
									Verifica
								</button>
							)}
							{isValidating && (
								<div
									className="
									input-group-text ">
									<div className="spinner-border text-primary spinner-border-sm" />
								</div>
							)}
						</div>
						{couponCodeError && (
							<div className="invalid-feedback">{couponCodeError}</div>
						)}
						{!couponCodeError && form.values.useDiscount && (
							<div className="valid-feedback">Codice valido</div>
						)}
					</div>
				</div>
			</fieldset>

			<dl className="row" style={{ marginBottom: 0 }}>
				<dt className="col-8">Valore codice:</dt>
				<dd className="col-4">{form.values.discountValue}&nbsp;€</dd>
			</dl>
		</div>
	);
};

export default DiscountCoupon