import React, { useState } from "react";
import * as AppStates from "../Services/AppStates";
import PurchaseForm from "./PurchaseForm";
import NoAmountOrderButton from "./NoAmountOrderButton";

function Recap(props) {
		let { amount } = props.data;

	return (
		<section>
			<h5 style={{ textAlign: "center" }}>Riepilogo</h5>

			<div className="recap-item">
				<p className="recap-header">Negozio selezionato</p>
				<p className="recap-content">{props.data.shop.name}</p>
			</div>

			<div className="recap-item">
				<p className="recap-header">Valore buono</p>
				<p className="recap-content">{amount.value}&nbsp;€</p>
			</div>

			<div className="recap-item">
				<p className="recap-header">Codice promozione</p>
				<p className="recap-content">{props.data.discountCode}</p>
			</div>

			<div className="recap-item">
				<p className="recap-header">Valore codice</p>
				<p className="recap-content">{props.data.discountValue}&nbsp;€</p>
			</div>

			<div className="recap-item">
				<p className="recap-header">Nome</p>
				<p className="recap-content">{props.data.name}</p>
			</div>

			<div className="recap-item">
				<p className="recap-header">Cognome</p>
				<p className="recap-content">{props.data.surname}</p>
			</div>

			<div className="recap-item">
				<p className="recap-header">E-Mail</p>
				<p className="recap-content">{props.data.mail}</p>
			</div>
		</section>
	);
}

function PurchaseOK(props) {
	function restart(e) {
		e.preventDefault();
		props.onRestart();
	}

	return (
		<>
			<h3 className="bottom-line">Acquisto completato!</h3>
			<p>
				A breve riceverai una e-mail di conferma con il coupn all'indirizzo
				specificato
			</p>
			<p style={{ overflowWrap: "break-word" }}>
				In caso di problemi comunicaci l'identificativo del tuo ordine:{" "}
				<b>{props.orderId}</b>
			</p>
			<div className="form-group">
				<button className="btn btn-primary form-control" onClick={restart}>
					Ricomincia
				</button>
			</div>
		</>
	);
}

function PurchaseError(props) {
	var msg;
	if (props.orderId)
		msg = (
			<p>
				Comunicaci il tuo orderId: <b>{props.orderId}</b>
			</p>
		);
	else msg = <p>Si prega di riprovare più tardi</p>;

	return (
		<>
			<h3 className="bottom-line">Si è verificato un problema</h3>
			{msg}
		</>
	);
}

function PurchaseSideBar(props) {
	const backToSelection = (e) => {
		e.preventDefault();
		props.setAppState(AppStates.INIT_STATE);
	};

	const [orderId, setOrderId] = useState(null);

	function onRestart() {
		setOrderId(null);
		props.onRestart();
	}

	return (
		<section id="sidebar" className="col-md-4">
			<section className="purchase-data">
				{(() => {
					switch (props.appState) {
						case AppStates.INIT_STATE:
							return <PurchaseForm {...props} />;

						case AppStates.CHECKOUT_STATE:
						case AppStates.CHECKOUT_FORM_SUBMITTING_STATE:
							return (
								<React.Fragment>
									<h3 className="bottom-line">Acquista</h3>

									<Recap data={props.data} />

									<div className="form-group">
										<button
											className="btn btn-dark form-control"
											disabled={
												props.appState ===
												AppStates.CHECKOUT_FORM_SUBMITTING_STATE
											}
											onClick={backToSelection}>
											&lt;&nbsp;Torna alla selezione
										</button>
									</div>

									{props.data.amount.payment === 0 && (
									<NoAmountOrderButton
										data={props.data}
										setOrderId={setOrderId}
										setAppState={props.setAppState}
										appState={props.appState}
									/>
									)}

									{/*props.data.amount.payment !== undefined &&
										props.data.amount.payment !== 0 && (
											<PayPalButton
												data={props.data}
												setOrderId={setOrderId}
												setAppState={props.setAppState}
												appState={props.appState}
												orderId={orderId}
											/>
										)*/}

									{props.appState ===
										AppStates.CHECKOUT_FORM_SUBMITTING_STATE && (
										<div>
											<i className="icofont-info-square"></i>
											<b>&nbsp;Verifica pagamento in corso, attendere prego.</b>
										</div>
									)}
								</React.Fragment>
							);

						case AppStates.PURCHASE_COMPLETE_STATE:
							return <PurchaseOK orderId={orderId} onRestart={onRestart} />;

						case AppStates.PURCHASE_ERROR_STATE:
							return <PurchaseError orderId={orderId} />;

						case AppStates.PREVIEW_STATE:
							return (
								<>
									<h3 className="bottom-line">Preview</h3>
									<p>
										L'utilizzo è al momento limitato ad un ristretto numero di
										utenti.
									</p>{" "}
									<p>Non preoccuparti, a breve sarà disponibile per tutti.</p>
								</>
							);
						default:
							return null;
					}
				})()}
			</section>
		</section>
	);
}

export default PurchaseSideBar;
