import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

function ModalMessage(props) {
	let modal = (
		<>
			<div
				className="modal show"
				role="dialog"
				style={{ display: "block" }}>
				<div className="modal-dialog modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{props.title}</h5>
							{/*<button
								type="button"
								className="close">
								<span aria-hidden="true">&times;</span>
							</button>*/}
						</div>
						<div className="modal-body">{props.children}</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary" onClick={props.close}>
								Ok
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop show" />
		</>
	);

	if (props.visible)
		return ReactDOM.createPortal(modal, document.getElementById("root"));
	else return null;
}

ModalMessage.propTypes = {
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string,
    close: PropTypes.func.isRequired,
    children: PropTypes.node

};

export default ModalMessage;
