import LoadingProgress from "../LoadingProgress";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Category, Extras, PageInfo, Shop } from "../../models";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import logo from "../../img/logo_big.svg";
import classNames from "classnames";

export type ShopsProps = {
	categories: Array<Category>;
	shops: Array<Shop>;
	isLoading: boolean;
	error?: Error;
	page: PageInfo;
	loadNextPage: () => void;
};

export default function Shops(props: ShopsProps) {
	const { shops, isLoading, error, page, loadNextPage } = props;

	/*const getCategoryName = useCallback(
		(categoryId?: string) => {
			const cat = categoryId
				? categories.find((category) => category.id === categoryId)
				: undefined;
			return cat ? cat.display : undefined;
		},
		[categories]
	);*/

	const shopsList = useMemo(() => {
		return shops.map((shop) => (
			<div className="card shop shadow" key={shop.id}>
				{/*<div className="side-color" />*/}
				<div className="d-md-flex justify-content-start">
					<div
						className={classNames("media-container", {
							"no-image": !shop.image,
						})}
						style={{
							backgroundImage: shop.image
								? "url(" + shop.image + ")"
								: "url(" + logo + ")",
						}}>
						{/*shop.image && <img src={shop.image} alt="Immagine negozio" />*/}
					</div>
					<div className="card-body media-body ml-2">
						<div className="d-flex justify-content-between">
							<h5 className="card-title">{shop.name}</h5>
							<div style={{ textAlign: "right" }}>
								{shop.extras?.includes(Extras.DELIVERY) ? (
									<Tippy content="Consegna a domicilio">
										<i
											className="icofont-vehicle-delivery-van"
											style={{ fontSize: "1.8rem" }}></i>
									</Tippy>
								) : null}
								{shop.extras?.includes(
									Extras.EXTRA_DISCOUNT_10
								) ? (
									<Tippy content="Sconto 10% del valore nominale del buono">
										<span className="badge badge-primary extras-badge">
											Sconto 10%
										</span>
									</Tippy>
								) : null}
								{shop.extras?.includes(Extras.CHARITY) ? (
									<Tippy content="Verr&agrave; donato il 10% degli acquisti effettuati al fondo di solidariet&agrave; alimentare">
										<span className="badge badge-primary extras-badge">
											Solidariet&agrave; alimentare
										</span>
									</Tippy>
								) : null}
							</div>
						</div>
						<small className="text-muted">
							{shop.address ? (
								<>
									Indirizzo: {shop.address}
									<br />
								</>
							) : null}
							{shop.category ? (
								<>Categoria: {shop.category}</>
							) : null}
						</small>
						<p>{shop.description}</p>
					</div>
				</div>
			</div>
		));
	}, [shops]);

	return (
		<section className="col-12 shops-container">
			{shopsList.length === 0 && !isLoading ? (
				<p className="text-center h4 mt-3">Nessuna attività trovata</p>
			) : (
				shopsList
			)}

			<LoadingProgress isLoading={isLoading} error={error}>
				{page ? (
					page.currentPage < page.totPages ? (
						<LoadMoreButtons loadNextPage={loadNextPage} />
					) : null
				) : null}
			</LoadingProgress>
		</section>
	);
}

function LoadMoreButtons(props: { loadNextPage: () => void }) {
	const el = useRef<HTMLButtonElement>(null);
	const { loadNextPage } = props;

	const loadMoreShops = useCallback(
		(e?: React.MouseEvent<HTMLButtonElement>) => {
			if (e) e.preventDefault();
			loadNextPage();
		},
		[loadNextPage]
	);

	const buttonVisible = useCallback(
		(entries, observer) => {
			const visible = entries[0].isIntersecting;

			if (visible) loadMoreShops();
		},
		[loadMoreShops]
	);

	useEffect(() => {
		const options = {
			threshold: 1,
		};

		//register new observer
		const observer = new IntersectionObserver(buttonVisible, options);
		if (el.current) observer.observe(el.current!);

		return () => {
			observer.disconnect();
		};
	}, [buttonVisible, el]);

	return (
		<button
			className="btn btn-outline-primary col-12"
			onClick={loadMoreShops}
			ref={el}>
			Carica altri negozi
		</button>
	);
}
