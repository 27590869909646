import React, { useCallback, useMemo } from "react";
import { Formik, Field, ErrorMessage, useFormikContext } from "formik";
import * as yup from "yup";
import CustomInputFieldError, { ErrorComponent } from "./CustomInputFieldError";
import { useState } from "react";
import { useEffect } from "react";
import DiscountCoupon from "./DiscountCoupn";

const initialValues = {
	amountIndex: "",
	name: "",
	surname: "",
	mail: "",
	useDiscount: true,
	discountCode: "",
	repeatMail: "",
	discountValue: undefined,
};

const validationSchema = yup.object().shape({
	amountIndex: yup.number().required("Campo Richiesto"),
	name: yup.string().required("Campo Richiesto"),
	surname: yup.string().required("Campo Richiesto"),
	mail: yup.string().email("E-Mail non valida").required("Campo Richiesto"),
	repeatMail: yup
		.string()
		.email("E-Mail non valida")
		.required("Campo Richiesto"),
});

function CustomSelect({ field, form, ...props }) {
	var style =
		"custom-select " +
		(form.touched[field.name] && form.errors[field.name]
			? "is-invalid"
			: "");
	return (
		<select {...field} {...props} className={style}>
			{props.children}
		</select>
	);
}

function PurchaseForm(props) {
	//validation error message, if undefined means no error
	const [couponCodeError, setCouponCodeError] = useState(true);

	const onSubmit = (values, formikBag) => {
		if (couponCodeError === undefined) {
				props.onCheckout(values, formikBag);
		} else formikBag.setFieldError("discountCode", couponCodeError); 
		return false;
	};

	/**
	 * Generate amount dropdown menu select options
	 * Update available amounts only if shop is changed
	 */
	var selectOptions = useMemo(() => {
		if (props.data.shop) {
			return props.data.shop.amounts.map((v, i) => (
				<option value={i} key={i}>
					{v.payment}
				</option>
			));
		} else return null;
	}, [props.data.shop]);

	/**
	 * This function generates the information for the selected shop
	 * Memorize the function on component cration
	 */
	const getShopInfo = useCallback((shop, amountIndex) => {
		var resp = {
			effectiveValue: "-",
			expiration: "-",
			conditions: "-",
			originalPayment: 0,
		};
		if (shop && amountIndex !== "") {
			resp.effectiveValue = shop.amounts[amountIndex].value + " €";
			resp.originalPayment = shop.amounts[amountIndex].payment;
		}
		if (shop) {
			resp.expiration = shop.expiration;
			resp.conditions = shop.conditions;
		}
		return resp;
	}, []);

	return (
		<React.Fragment>
			<h3 className="bottom-line">Procedi all'acquisto</h3>

			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				>
				{({ values, handleSubmit}) => {
					const {
						effectiveValue,
						expiration,
						conditions,
						originalPayment,
					} = getShopInfo(props.data.shop, values.amountIndex);

					return (
						<form onSubmit={handleSubmit}>
							<fieldset disabled={props.disabled}>
								
								<div className="form-group">
									<label htmlFor="amountIndex">
										Selezionare l'importo&nbsp;
										<span className="required-field">
											*
										</span>
									</label>

									<div className="input-group">
										<Field
											name="amountIndex"
											component={CustomSelect}>
											<option value="" disabled>
												Seleziona un importo
											</option>
											{selectOptions}
										</Field>

										<div className="input-group-append">
											<span className="input-group-text">
												€
											</span>
										</div>
										<ErrorMessage
											name="amountIndex"
											component={ErrorComponent}
										/>
									</div>
								</div>

								<div className="form-group">
									<dl className="row">
										<dt className="col-6">
											Valore buono:{" "}
										</dt>
										<dd className="col-6">
											{effectiveValue}
										</dd>

										<dt className="col-6">Valido fino: </dt>
										<dd className="col-6">{expiration}</dd>

										<dt className="col-6">Condizioni: </dt>
										<dd className="col-6">{conditions}</dd>
									</dl>
								</div>

								<div className="form-group">
									<label htmlFor="name">
										Nome&nbsp;
										<span className="required-field">*</span>
									</label>

									<CustomInputFieldError
										type="text"
										name="name"
										placeholder="Nome"
									/>
								</div>

								<div className="form-group">
									<label htmlFor="surname">
										Cognome&nbsp;
										<span className="required-field">*</span>
									</label>
									<CustomInputFieldError
										type="text"
										name="surname"
										placeholder="Cognome"
									/>
								</div>

								<div className="form-group">
									<label htmlFor="mail">
										E-Mail&nbsp;
										<span className="required-field">*</span>
									</label>
									<CustomInputFieldError
										type="mail"
										name="mail"
										placeholder="E-Mail"
									/>
								</div>

								<RepeatMail name="repeatMail" />

								<label htmlFor="discountcode">
									Inserisci un codice promozionale&nbsp;
									<span className="required-field">*</span>
								</label>

								<Field name="discountCode">
									{(fieldProps) => (
										<DiscountCoupon
											{...fieldProps}
											setCouponCodeError={setCouponCodeError}
											couponCodeError={couponCodeError}
											originalPayment={originalPayment}
											shopCode={props.data.shop ? props.data.shop.id : null}
										/>
									)}
								</Field>

								<div className="form-group">
									<button
									
										className="btn btn-primary form-control"
										type="submit">
										Acquista
									</button>
								</div>
							</fieldset>
						</form>
					);
				}}
			</Formik>

			<small className="text-muted">
				<span className="required-field">*</span>
				Campi Richiesti
			</small>
		</React.Fragment>
	);
}

const RepeatMail = (props) => {
	const {
		values: { mail, repeatMail },
		touched,
		setFieldError,
		errors,
	} = useFormikContext();

	//validate field
	useEffect(() => {
		if (mail !== repeatMail)
			setFieldError(props.name, "E-Mail non corrispondenti");
	}, [mail, repeatMail, touched, setFieldError, errors, props.name]);

	return (
		<div className="form-group">
			<label htmlFor={props.name}>
				Ripeti E-Mail&nbsp;
				<span className="required-field">*</span>
			</label>
			<CustomInputFieldError
				type="mail"
				name={props.name}
				placeholder="Ripeti E-Mail"
			/>
		</div>
	);
};

export default PurchaseForm;
