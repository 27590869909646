import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

const alertInitState = { status: null, msg: "", visible: false };

function Alert({ alert, onCloseAlert, dismissable = true }) {
	if (alert && alert.visible)
		return (
			<div
				className={cn("alert", "fade", "show", {
					"alert-success": alert.status === "OK",
					"alert-danger": alert.status === "ERROR",
					"alert-info": alert.status === "INFO",
					"alert-dismissable": dismissable,
				})}
				role="alert">
				<span>{alert.msg}</span>
				{dismissable && (
					<button
						type="button"
						className="close"
						aria-label="Close"
						onClick={onCloseAlert}>
						<span aria-hidden="true">&times;</span>
					</button>
				)}
			</div>
		);
	else return null;
}

const alertObjectShape = {
	visible: PropTypes.bool.isRequired,
	msg: PropTypes.string,
	status: PropTypes.oneOf(["OK", "ERROR", "INFO"]),
};

alert.propsType = {
	onCloseAlert: PropTypes.func,
	alert: PropTypes.shape(alertObjectShape).isRequired,
};

export default Alert;
export { alertInitState, alertObjectShape };
