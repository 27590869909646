import React from "react";
import logo from "../img/logo.svg";
import { Link } from "react-router-dom";
import image from "../img/shopping_vouchers_shop_logo.svg";

const scrollToContent = () => {
	document.getElementById("content").scrollIntoView({ behavior: "smooth" });
};

export default function ShoppingVouchersShop() {
	return (
		<>
			<header id="intro" className="dark-blu-bg">
				<div className="container">
					<div id="logo">
						<Link to="/">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div
						id="intro-content"
						style={{ top: "0" }}
						className="shopping-vouchers-intro">
						<div className="row">
							<div
								className="col-md-6 intro-title"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<p>
									Benvenuto sullo
									<br /> spazio dedicato ai
									<br />
									<b>buoni spesa!</b>
								</p>

								<div>
									<button onClick={scrollToContent}>
										SCOPRI COME FUNZIONA
									</button>
								</div>
								<div>
									<a
										className="btn white-button"
										href="https://dashboard.clusu.com">
										ACCEDI ALLA DASHBOARD
									</a>
								</div>
							</div>
							<div
								className="col-md-6"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<img
									src={image}
									alt="shopping vouchers logo"
									style={{
										maxWidth: "80%",
										maxHeight: "70vh",
										margin: "auto",
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div id="mouse-scroll">
					<span>
						<i className="icofont-scroll-down"></i>
					</span>
				</div>
			</header>

			<section className="info-section" id="content">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									L'INIZIATIVA
								</span>
							</div>
							<p className="info-content text-center">
								L’Amministrazione Comunale di CLUSONE intende
								procedere all’individuazione degli esercizi
								commerciali aventi sede nel Comune di Clusone
								che intendono dare la propria disponibilità ad
								accettare buoni spesa, emessi dal Comune,
								finalizzati alla riduzione del costo sostenuto
								dalle famiglie{" "}
								<b>
									per l’acquisto di generi alimentari e di
									prima necessità
								</b>
								, di valore variabile.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section
				style={{ backgroundColor: "#006F92", color: "white" }}
				className="info-section">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									IL BUONO SPESA
								</span>
							</div>
							<p className="info-content text-center">
								I buoni vengono rilasciati esclusivamente in
								taglio da 25€ e 50€ e riportano il codice
								fiscale del beneficiario, il codice
								identificativo del buono, il logo del Comune di
								Clusone sulla destra e sulla sinistra in basso
								(vicino al codice fiscale) riportano il timbro a
								secco del Comune.
								<br />
								<br />
								Si precisa che:
							</p>
						</div>
					</div>

					<div className="info-section row justify-content-center">
						<div className="col-md-6">
							<p className="info-content">
								- il buono spesa non è trasferibile, né cedibile
								a persone diverse dal beneficiario;
								<br />
								- il buono spesa non è in alcun modo
								monetizzabile e non sono ammissibili forme di
								compensazione o rimborso, anche parziale, di
								prestazioni non usufruite o non usufruite
								integralmente;
								<br />
								- il buono spesa ha validità massima fino al 30
								giugno 2021
								<br />
								- deve essere speso esclusivamente presso gli
								operatori economici convenzionati con l’Ente;
								<br />
							</p>
						</div>
						<div className="col-md-6">
							<p className="info-content">
								- il buono spesa può essere utilizzato per
								acquistare beni di prima necessità, quali:
								<br />
								<span style={{ marginLeft: "1rem" }}>
									- Generi alimentari e bevande, ad esclusione
									di alcolici;
								</span>
								<br />
								<span style={{ marginLeft: "1rem" }}>
									- Farmaci, articoli medicali e ortopedici;
								</span>
								<br />
								<span style={{ marginLeft: "1rem" }}>
									- Prodotti per l’igiene personale e la casa;{" "}
								</span>
								<br />
								<span style={{ marginLeft: "1rem" }}>
									- Beni per l’infanzia;
								</span>
								<br />
								<span style={{ marginLeft: "1rem" }}>
									- Beni per gli animali domestici;
								</span>
								<br />
								<span style={{ marginLeft: "1rem" }}>
									- Beni per garantire l’attività
									didattica/educativa dei minori
								</span>
								<br />- comporta l’obbligo per il fruitore di
								regolare in contanti l’eventuale differenza in
								eccesso tra il valore facciale del buono ed il
								prezzo dei beni acquistati. La scelta
								dell’esercizio commerciale tra quelli aderenti
								all’iniziativa è rimessa alla libera scelta dei
								beneficiari.
							</p>
						</div>
					</div>

					<div className="row justify-content-center">
						<a
							className="btn white-button col-6"
							href="/buoni-spesa/esercente/richiesta">
							ADERISCI COME ESERCENTE
						</a>
					</div>
				</div>
			</section>

			<section style={{ position: "relative" }}>
				<div
					style={{
						backgroundColor: "#006F92",
						position: "absolute",
						width: "100%",
						height: "30%",
					}}
				/>
				<div className="container">
					<div className="info-section row justify-content-center">
						<div
							className="col-md-10"
							style={{
								backgroundColor: "#00B2EA",
								color: "white",
								borderRadius: "15px",
							}}>
							<div className="info-header">
								<span className="bottom-line">ATTENZIONE!</span>
							</div>
							<div className="row justify-content-center">
								<p className="info-content text-center col-10">
									La disponibilità da parte degli esercizi
									commerciali viene raccolta tramite il
									seguente{" "}
									<a
										href="/buoni-spesa/esercente/richiesta"
										style={{
											color: "white",
											fontWeight: "bold",
										}}>
										link
									</a>
									; l’esercizio commerciale, solo dopo
									l’accettazione da parte dei Servizi Sociali,
									viene convenzionato con il Comune per la
									specifica misura e iscritto nell’apposito
									elenco pubblicato sul sito Internet
									comunale.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="info-section">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									RIMBORSO BUONI SPESA
								</span>
							</div>
							<p className="info-content text-center">
								L’esercente rilascia al cittadino regolare
								scontrino per il totale della spesa; trasmette
								mensilmente al Comune di Clusone una nota di
								rimborso, allegando un file riepilogativo
								generato dalla piattaforma.
								<br /> Il Comune di Clusone liquiderà al
								commerciante la somma corrispondente al totale
								dei buoni spesa presentati.
							</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="info-header">
								<span className="bottom-line">
									INFORMATIVA COMPLETA
								</span>
							</div>
							<p className="info-content text-center">
								L'informativa completa è disponibile al seguente{" "}
								<a
									href="http://www.comune.clusone.bg.it/dettagli.aspx?c=1&sc=4&id=795&tbl=news"
									target="_blank"
									rel="noopener noreferrer">
									link
								</a>
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
